import { Box, Button, Typography } from "@mui/material";
import React from "react";
import TreffasStyle from "src/utils/TreffasStyle";
import TL from "src/assets/images/treffas_logo.png";
import FT from "src/assets/images/bg-footer.png";
import { useNavigate } from "react-router-dom";

const FooterImage = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(${FT})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundColor: "#cccccc",
          backgroundSize: "cover",
          maxWidth: { xs: "100%", sm: "668px" },
          height: { xs: "188px", sm: "80px" },
          display: "flex",
          alignItems: "center",
          justifyContent: { xs: "flex-start", sm: "center" },
          pl: { xs: "32px", sm: "0px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: { xs: "flex-start", sm: "center" },
            padding: "0px",
            gap: { xs: "20px", sm: "59px" },
            flexDirection: { xs: "column", sm: "row" },
            px: "5px",
          }}
        >
          <Box>
            <img
              src={TL}
              alt={"treffas logo"}
              style={{
                width: "106px",
                height: "17px",
              }}
            />
          </Box>

          <Box>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "21px",
              }}
            >
              Make scheduling your next <br /> meeting easy with Treffas!
            </Typography>
          </Box>

          <Box>
            <Button
              variant="contained"
              sx={{
                minWidth: "107px",
                fontSize: "12px",
                height: "36px",
                borderRadius: "8px",
                ...TreffasStyle.buttonHover,
              }}
              onClick={() => navigate(`/register`)}
            >
              Start for free
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FooterImage;
