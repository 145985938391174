import React, { useState } from "react";
import { useParams } from "react-router-dom";
import jwtDecode from "jwt-decode";
import http from "src/utils/http";
import SideBg from "../../SideBg";
import {
  Box,
  Grid,
  Typography,
  useTheme,
  TextField,
  Autocomplete,
  Button,
  CircularProgress,
} from "@mui/material";
import Logo from "src/components/Logo";
import * as Yup from "yup";
import { useForm, Controller as Fc } from "react-hook-form";
import TreffasStyle from "src/utils/TreffasStyle";
import { yupResolver } from "@hookform/resolvers/yup";
import Itnl from "src/utils/Itnl";
import Verification from "../treffas/Verification";

const Schema = Yup.object({
  organization: Yup.string().required("Organization is a required field"),
  firstname: Yup.string().required("Firstname is a required field"),
  lastname: Yup.string().required("Lastname is a required field"),

  password: Yup.string()
    .required("Password is a required field")
    .min(6, "Password is too short - should be 6 chars minimum."),
  password_confirm: Yup.string()
    .required("Password confirm is a required field")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),

  timezone: Yup.string().required("Password is a required field"),
  currency: Yup.string().required("Currency is a required field"),

  email: Yup.string()
    .email("Enter valid email address.")
    .required("Email is a required field"),
}).required();

const SetupPassword = () => {
  const themes = useTheme();
  const { googleToken } = useParams();
  const googleData = jwtDecode(googleToken);
  const [selectedTimezone, setSelectedTimezone] = useState(null);
  const [category, setCategory] = useState("setup-timezone-password"); /// setup-timezone-password ,  verification
  const [isProcess, setIsProcess] = useState(false);

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
    defaultValues: {
      firstname: googleData.given_name,
      lastname: googleData.family_name,
      organization: googleData.name?.trim()?.toLowerCase(),
      name: googleData.name,
      password: "",
      password_confirm: "",
      timezone: "",
      timezone_utc: "",
      currency: "USD",
      email: googleData.email,
    },
  });

  const onSubmit = (values) => {
    setIsProcess(true);
    http
      .post("/api/auth/registration", { ...values })
      .then(({ data }) => {
        if (data.message === "email-exist") {
          // enqueueSnackbar("Email address is not available.");
          setError("email", {
            type: "required",
            message: "Email address is not available",
          });
        }

        if (data.message === "success") {
          setCategory("verification");
        }
      })
      .catch((err) => {
        console.log(`error:`, err.message);
      })
      .finally(() => setIsProcess(false));
  };

  return (
    <>
      <Box
        sx={
          {
            // border: "1px solid red",
          }
        }
      >
        <Grid container>
          <Grid item xs={12} md={12} lg={7} xl={8}>
            <Box>
              {/* logo */}
              <Box
                sx={{
                  height: "80px",
                  display: "flex",
                  alignItems: "center",
                  pl: "99px",
                  boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.04)",
                }}
              >
                <Logo />
              </Box>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "426px",
                    m: "auto",
                  }}
                >
                  {/* setup timezone and passowrd */}
                  {category === "setup-timezone-password" && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        height: "calc(100vh - 100px)",
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: "32px",
                            lineHeight: "46px",
                            textAlign: "center",
                            letterSpacing: "-0.01em",
                            color: "#060A2D",
                          }}
                        >
                          Just few more details
                        </Typography>

                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: "32px",
                            lineHeight: "46px",
                            textAlign: "center",
                            letterSpacing: "-0.01em",
                            color: "#060A2D",
                          }}
                        >
                          and your done!
                        </Typography>

                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "24px",
                            textAlign: "center",
                            color: "#838383",
                          }}
                        >
                          Sign up today to get a 14-day free trial of the pro
                          plan.
                        </Typography>
                      </Box>

                      <form onSubmit={handleSubmit(onSubmit)}>
                        <Box mt={"24px"}>
                          <Typography sx={TreffasStyle.formLabel}>
                            Name
                          </Typography>

                          <Fc
                            name="name"
                            control={control}
                            rules={{
                              required: true,
                            }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                placeholder="Enter name"
                                type="name"
                                sx={TreffasStyle.formInput(themes)}
                                error={Boolean(errors.name)}
                                helperText={errors.name?.message}
                                InputProps={{ readOnly: true }}
                              />
                            )}
                          />
                        </Box>

                        <Box mt={"24px"}>
                          <Typography sx={TreffasStyle.formLabel}>
                            Email
                          </Typography>

                          <Fc
                            name="email"
                            control={control}
                            rules={{
                              required: true,
                            }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                placeholder="Enter email"
                                type="email"
                                sx={TreffasStyle.formInput(themes)}
                                error={Boolean(errors.email)}
                                helperText={errors.email?.message}
                                InputProps={{ readOnly: true }}
                              />
                            )}
                          />
                        </Box>

                        <Box mt={"24px"}>
                          <Typography sx={TreffasStyle.formLabel}>
                            Enter password
                          </Typography>

                          <Fc
                            name="password"
                            control={control}
                            rules={{
                              required: true,
                            }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                placeholder="Enter password"
                                type="password"
                                sx={TreffasStyle.formInput(themes)}
                                error={Boolean(errors.password)}
                                helperText={errors.password?.message}
                              />
                            )}
                          />
                        </Box>

                        <Box sx={{ mt: "24px" }}>
                          <Typography sx={TreffasStyle.formLabel}>
                            Re-enter password
                          </Typography>

                          <Fc
                            name="password_confirm"
                            control={control}
                            rules={{
                              required: true,
                            }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                placeholder="Re-enter password"
                                type="password"
                                sx={TreffasStyle.formInput(themes)}
                                error={Boolean(errors.password_confirm)}
                                helperText={errors.password_confirm?.message}
                              />
                            )}
                          />
                        </Box>

                        <Box mt={"24px"}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                              <Box>
                                <Typography sx={TreffasStyle.formLabel}>
                                  Time zone
                                </Typography>
                                <Fc
                                  name="timezone"
                                  control={control}
                                  rules={{
                                    required: true,
                                  }}
                                  render={({ field }) => (
                                    <Autocomplete
                                      options={Itnl.timezones}
                                      disablePortal
                                      disableClearable
                                      onChange={(event, newValue) => {
                                        setSelectedTimezone(newValue.value);
                                        setValue("timezone", newValue.value);
                                        setValue(
                                          "timezone_utc",
                                          newValue?.utc?.[0]
                                        );
                                      }}
                                      value={
                                        Itnl.timezones.find(
                                          (v) => v.value === selectedTimezone
                                        ) ?? null
                                      }
                                      getOptionLabel={(option) => option.label}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          {...field}
                                          placeholder="(GTM+01:00) Sto..."
                                          sx={TreffasStyle.autocomplete.field}
                                          error={Boolean(errors.timezone)}
                                          helperText={errors.timezone?.message}
                                        />
                                      )}
                                      ListboxProps={{
                                        sx: {
                                          fontSize: "14px !important",
                                          lineHeight: "24px !important",
                                        },
                                      }}
                                    />
                                  )}
                                />
                              </Box>
                            </Grid>

                            <Grid item xs={12} md={6}>
                              <Box>
                                <Typography sx={TreffasStyle.formLabel}>
                                  Currency
                                </Typography>

                                <Fc
                                  name="currency"
                                  control={control}
                                  rules={{
                                    required: true,
                                  }}
                                  render={({ field }) => (
                                    <TextField
                                      {...field}
                                      fullWidth
                                      placeholder="USD"
                                      type="text"
                                      sx={TreffasStyle.formInput(themes)}
                                      error={Boolean(errors.currency)}
                                      helperText={errors.currency?.message}
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  )}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>

                        <Box sx={{ mt: "40px" }}>
                          <Button
                            variant="contained"
                            fullWidth
                            sx={{
                              textTransform: "inherit",
                              borderRadius: "8px",
                              fontSize: "14px",
                              padding: "10px",
                              lineHeight: "31px",
                              fontWeight: 400,
                              height: "48px",
                            }}
                            type="submit"
                            disabled={isProcess}
                            startIcon={
                              isProcess && (
                                <CircularProgress color="inherit" size={15} />
                              )
                            }
                          >
                            Submit
                          </Button>
                        </Box>
                      </form>
                    </Box>
                  )}

                  {/* verify email */}
                  {category === "verification" && (
                    <Verification email={getValues("email")} />
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={12} lg={5} xl={4}>
            <SideBg />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SetupPassword;
