import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import GuardedRoutes from "src/routes/guarded";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/system";
import Logo from "src/components/Logo";
import { Badge, ButtonBase, Container, Link } from "@mui/material";
import { ChevronDown, ChevronUp } from "react-feather";
import { Notification, Users } from "src/context";
import http from "src/utils/http";
import Pusher from "pusher-js";
import Constant from "src/utils/Constant";
import NoImage from "src/assets/images/no-image.png";
import TopbarMobile from "./mobile";
import { useTranslation } from "react-i18next";
import DropdownMenu from "./dropdown-menu";
import IconRoundedBell from "src/components/svg-icons/IconRoundedBell";
import DropdownNotification from "./dropdown-notification";

const GuardedTopBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElSubRoute, setAnchorElSubRoute] = React.useState(null);
  const [anchorElSubRouteKey, setAnchorElSubRouteKey] = React.useState(null);

  const [notificationAnchor, setNotificationAnchor] = React.useState(null);

  const [mobileAnchorElSubRoute, setMobileAnchorElSubRoute] =
    React.useState(null);
  const [mobileAnchorElSubRouteKey, setMobileAnchorElSubRouteKey] =
    React.useState(null);

  const navigate = useNavigate();
  const theme = useTheme();
  const { user } = React.useContext(Users);
  const { notifications, setNotifications } = React.useContext(Notification);
  const { t } = useTranslation();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const getUnreadNotificationCount = () => {
    // http
    //   .get(`/api/notifications/unread-count`, {
    //     params: {
    //       user_id: user?.id,
    //       organization_id: user?.organization?.id,
    //     },
    //   })
    //   .then((response) => {
    //     setNotifications((notifications) =>
    //       notifications.concat(response.data)
    //     );
    //   });

    http
      .get("/api/notifications/list", {
        params: {
          order_by: "desc",
          page: 1,
          user_id: user?.id,
          organization_id: user?.organization?.id,
          limit: 100,
          search: "",
          type: "all",
        },
      })
      .then((response) => {
        setNotifications((notifications) =>
          notifications.concat(response.data.data.data)
        );
      });
  };

  React.useEffect(() => {
    getUnreadNotificationCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // setup pusher for notificaiotns detections
  React.useEffect(() => {
    var pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
    });

    var channel = pusher.subscribe(`treffas-notification-channel-${user?.id}`);

    channel.bind(
      `treffas-notification-channel-event-${user?.id}`,
      function (data) {
        // console.log("data from channel:", data.notifications);
        setNotifications((notifications) =>
          notifications.concat(data.notifications)
        );
      }
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const subscription = user?.subscription
    ? JSON.parse(user?.subscription)
    : null;

  const currentPlan = subscription?.plan_name
    ? subscription?.plan_name
    : "Free";

  const unReadCount =
    notifications.filter((x) => parseInt(x.is_read) === 0)?.length ?? 0;

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "#fff",
        borderBottom: { xs: "none", md: "1px solid #EDEDED" },
      }}
      elevation={0}
    >
      <Container
        maxWidth="false"
        sx={(themes) => ({
          py: "0px",
          px: { xs: "10px", md: "40px" },
          mt: { xs: "40px", md: "0px" },
        })}
      >
        <Box>
          <Toolbar disableGutters>
            <Box
              sx={{
                display: { xs: "none", md: "block" },
                width: "200px",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Logo />
            </Box>

            {/* mobile */}
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", md: "none" },
              }}
            >
              <TopbarMobile
                handleOpenNavMenu={handleOpenNavMenu}
                anchorElNav={anchorElNav}
                handleCloseNavMenu={handleCloseNavMenu}
                GuardedRoutes={GuardedRoutes}
                navigate={navigate}
                mobileAnchorElSubRouteKey={mobileAnchorElSubRouteKey}
                mobileAnchorElSubRoute={mobileAnchorElSubRoute}
                notificationCount={unReadCount}
                setMobileAnchorElSubRoute={setMobileAnchorElSubRoute}
                setMobileAnchorElSubRouteKey={setMobileAnchorElSubRouteKey}
              />
            </Box>
            {/* mobile screen end */}

            {/* large screen appbar end */}
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "center",
              }}
            >
              {GuardedRoutes.filter((page) => page.show).map((page, key) => {
                let path = page.path.replace("*", "");

                return (
                  <React.Fragment key={key}>
                    <Button
                      conponent={Link}
                      href={page.withSubs ? "#" : path}
                      id={key}
                      onClick={(e) => {
                        // Prevent the NavLink from navigating
                        e.preventDefault();

                        if (page.withSubs) {
                          setAnchorElSubRoute(e.currentTarget);
                          setAnchorElSubRouteKey(key);
                          return;
                        }

                        navigate(path);
                      }}
                      sx={{
                        padding: 0,
                        margin: 0,
                        // ml: "24px",
                        px: "12px",
                        color: window.location.pathname.includes(path)
                          ? "#0173FE"
                          : "#060A2D",
                        // display: "block",
                        textTransform: "inherit",
                        height: "80px",
                        borderBottom:
                          window.location.pathname.includes(path) &&
                          `3px solid #0173FE`,
                        borderRadius: 0,
                        fontSize: "14px",
                        fontWeight: 500,
                        lineHeight: "21px",
                      }}
                      endIcon={
                        page.withSubs ? (
                          anchorElSubRouteKey === key ? (
                            <ChevronUp size={15} />
                          ) : (
                            <ChevronDown size={15} />
                          )
                        ) : null
                      }
                    >
                      {t(page.name)}
                    </Button>

                    {page.withSubs && anchorElSubRouteKey === key && (
                      <Menu
                        id={`menu-appbar-${key}`}
                        anchorEl={anchorElSubRoute}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                        open={Boolean(anchorElSubRoute)}
                        onClose={() => {
                          setAnchorElSubRoute(null);
                          setAnchorElSubRouteKey(null);
                        }}
                        elevation={0}
                        PaperProps={{
                          sx: {
                            mt: "70px",
                            // border: "1px solid #DBDCE1",
                            padding: "0px !important",
                            boxShadow: "0px 16px 32px rgba(0, 0, 0, 0.08)",
                          },
                        }}
                        sx={{
                          "& .MuiList-padding": {
                            padding: 0,
                          },
                        }}
                      >
                        {page.routes
                          .filter((page) => page.show !== false)
                          .map((subPage) => (
                            <MenuItem
                              component={Link}
                              href={`${path.replace("*", "")}${subPage.path}`}
                              key={subPage.name}
                              onClick={(e) => {
                                e.preventDefault();

                                setAnchorElSubRoute(null);
                                navigate(
                                  `${path.replace("*", "")}${subPage.path}`
                                );
                              }}
                              selected={window.location.pathname.includes(
                                `${path.replace("*", "")}${subPage.path}`
                              )}
                              sx={{
                                py: 1.3,
                              }}
                            >
                              <Typography
                                sx={{
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  lineHeight: "18px",
                                  alignItems: "center",
                                  color: "#060A2D",
                                }}
                              >
                                {t(subPage.name)}
                              </Typography>
                            </MenuItem>
                          ))}
                      </Menu>
                    )}
                  </React.Fragment>
                );
              })}
            </Box>

            {/* large screen appbar */}
            <Box
              sx={{
                flexGrow: 0,
                display: "flex",
                width: { xs: "46px", md: "200px" },
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: { xs: "none", md: "block" },
                  }}
                >
                  <ButtonBase
                    onClick={(e) => {
                      setNotificationAnchor(e.currentTarget);
                    }}
                  >
                    <Badge
                      badgeContent={unReadCount}
                      variant="standard"
                      color="error"
                    >
                      <IconRoundedBell />
                    </Badge>
                  </ButtonBase>

                  {/* dropdown notification */}
                  <DropdownNotification
                    notificationAnchor={notificationAnchor}
                    setNotificationAnchor={setNotificationAnchor}
                    notifications={notifications}
                    getUnreadNotificationCount={getUnreadNotificationCount}
                    unReadCount={unReadCount}
                  />
                </Box>

                <Box>
                  <Tooltip title="Open settings">
                    <IconButton
                      onClick={handleOpenUserMenu}
                      sx={{
                        p: "0px",
                      }}
                    >
                      <Avatar
                        sx={{
                          [theme.breakpoints.up("md")]: {
                            height: "40px",
                            width: "40px",
                          },
                        }}
                        alt={user?.name}
                        src={
                          user?.avatar
                            ? Constant.imageUrl("coach", user?.avatar)
                            : NoImage
                        }
                      />
                    </IconButton>
                  </Tooltip>

                  {/* dropdown menu for web browser */}
                  <DropdownMenu
                    anchorElUser={anchorElUser}
                    handleCloseUserMenu={handleCloseUserMenu}
                    currentPlan={currentPlan}
                    setAnchorElUser={setAnchorElUser}
                  />
                </Box>
              </Box>
            </Box>
          </Toolbar>
        </Box>
      </Container>
    </AppBar>
  );
};

export default GuardedTopBar;

// const ChipsStyle = {
//   background: "linear-gradient(0deg, #DEFCF3, #DEFCF3), #FFFFFF",
//   borderRadius: "4px",
//   minWidth: "43px",
//   height: "26px",
//   padding: "4px 8px !important",
//   "& .MuiChip-label": {
//     color: "#009356",
//     fontSize: "12px",
//     lineHeight: "14px",
//     fontWeight: 500,
//   },
// };
