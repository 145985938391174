import { Box, Button, IconButton, Typography } from "@mui/material";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { ArrowLeft, Copy, X } from "react-feather";
import TreffasTextField from "src/components/TreffasTextField";
import TreffasStyle from "src/utils/TreffasStyle";
import TreffasButtonImg from "src/assets/images/treffas-button.png";
import { useTranslation } from "react-i18next";
import { useSnackbarContext } from "src/context/SnackbarContext";

const EmbedButton = ({ bookingURL, close, onChangeCategory }) => {
  const { t } = useTranslation();
  let buttonURL = `<a href="${bookingURL}"><img src="${window.location.origin}${TreffasButtonImg}" style="border-style: none" /></a>`;

  const { showSnackbar } = useSnackbarContext();
  return (
    <Box>
      <Box
        sx={{
          p: "20px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <IconButton onClick={() => onChangeCategory("booking")}>
              <ArrowLeft size={20} />
            </IconButton>
          </Box>

          <Box>
            <IconButton onClick={() => close()}>
              <X size={20} />
            </IconButton>
          </Box>
        </Box>

        <Box sx={{ mt: "4px" }}>
          <Typography
            sx={{
              fontFamily: "Mukta",
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "26px",
              lineHeight: "26px",
              letterSpacing: "-0.01em",
              color: "#000000",
              textAlign: "center",
            }}
          >
            {t("package.label.booking.add_btn_toyour_web")}
          </Typography>
        </Box>

        <Box sx={{ mt: "8px" }}>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "18px",
              textAlign: "center",
              color: "#6E757C",
            }}
          >
            {t("package.label.booking.add_btn_toyour_web.description")}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          px: "40px",
          mt: "28px",
        }}
      >
        <Box>
          <TreffasTextField
            InputProps={{ readOnly: true }}
            value={buttonURL}
            fullWidth
            sx={{
              ...TreffasStyle.formInputLarge("textarea"),
              "& .MuiOutlinedInput-root": {
                borderRadius: "10px !important",
                backgroundColor: "#F4F5F6",
                // minHeight: "100%",
              },
              "& .MuiInputBase-input": {
                fontWeight: 400,
                fontSize: "13px",
                color: "#353A3F",
              },
            }}
            multiline
          />
        </Box>
      </Box>

      <Box
        sx={{
          px: "40px",
          mt: "16px",
          pb: "40px",
        }}
      >
        <CopyToClipboard
          text={buttonURL}
          onCopy={() =>
            showSnackbar(
              t("individual.booking.embed_code.copy.clipboard"),
              "success"
            )
          }
        >
          <Button
            variant="contained"
            fullWidth
            sx={{
              minHeight: "44px",
              fontWeight: 700,
              fontSize: "15px",
              lineHeight: "20px",
              borderRadius: "8px",
              ...TreffasStyle.buttonHover,
            }}
            startIcon={<Copy />}
          >
            {t("package.button.copy_embed_code")}
          </Button>
        </CopyToClipboard>
      </Box>
    </Box>
  );
};

export default EmbedButton;
