import { Box } from "@mui/material";
import React from "react";
import TL from "./../assets/images/folyeo.png";

const Logo = () => {
  return (
    <>
      {/* medium  sc logo */}
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <img
          src={TL}
          alt={"treffas logo"}
          style={{
            height: "35px",
            // width: "182px",
            cursor: "pointer",
          }}
          onClick={() => (window.location.href = "https://treffas.com")}
        />
      </Box>

      {/* sm  sc logo */}
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <img
          src={TL}
          alt={"treffas logo"}
          style={{
            height: "30px",
            // width: "97px",
            cursor: "pointer",
            marginTop: "16px",
          }}
          onClick={() => (window.location.href = "https://treffas.com")}
        />
      </Box>
    </>
  );
};

export default Logo;
