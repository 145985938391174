import { Box, Grid, Typography, FormHelperText } from "@mui/material";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import TreffasStyle from "src/utils/TreffasStyle";
import { Controller as Fc } from "react-hook-form";
import StepperGuide from "src/components/StepperGuide";
import TreffasTextField from "src/components/TreffasTextField";
import { useSnackbarContext } from "src/context/SnackbarContext";

const Survey = ({
  selectedOffer,
  errors,
  control,
  randomErrors,
  setValue,
  getValues,
  stepperGuideLength,
}) => {
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbarContext();

  const handleHasRequired = () => {
    if (selectedOffer?.survey.length) {
      selectedOffer?.survey.forEach((details) => {
        if (details?.questionslist.length > 0) {
          details?.questionslist.forEach((item) => {
            let field = "";
            if (item.type === "question-short-answer") {
              field = `answer[_${item.id}][_${item.choices[0].id}]`;
            }

            if (item.type === "question-comment-essay") {
              field = `answer[_${item.id}][_${item.choices[0].id}]`;
            }

            if (item.type === "question-one-answer") {
              field = `answer[_${item.id}]`;
            }

            if (item.type === "question-multiple-answer") {
              field = `answer[_${item.id}]`;
            }

            if (item.type === "question-true-false") {
              field = `answer[_${item.id}]`;
            }

            if (item.type === "question-yes-no") {
              field = `answer[_${item.id}]`;
            }

            let currentValue = getValues(field) ?? "";

            setValue(field, currentValue);
          });
        }
      });
    }
  };

  React.useEffect(() => {
    handleHasRequired();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "10px",
          mb: "50px",
          mt: "58px",
        }}
      >
        <Box>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "18px",
              lineHeight: "23px",
              alignItems: "center",
              color: "#060A2D",
            }}
          >
            {t("individual.booking.schedule.survey")}
          </Typography>

          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "18px",
              color: "#878787",
            }}
          >
            {t("individual.booking.schedule.survey.take_to_continue")}
          </Typography>
        </Box>

        <Box>
          <StepperGuide length={stepperGuideLength} activeLength={[1, 2, 3]} />
        </Box>
      </Box>

      {selectedOffer?.survey.map((details, surveyKey) => {
        return (
          <Fragment key={surveyKey}>
            <Grid item xs={12}>
              <Box>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "31px",
                    textTransform: "capitalize",
                  }}
                >
                  {details.name}
                </Typography>

                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "18px",
                    color: "#878787",
                  }}
                >
                  {details.description}
                </Typography>
              </Box>

              <Box>
                {details?.questionslist.length > 0 ? (
                  <>
                    {details?.questionslist.map((item, key) => {
                      return (
                        <Box
                          key={key}
                          sx={{
                            mt: "23px",
                            mb: 3,
                            border: "1px solid #D9DBE9",
                            borderRadius: "8px",
                            padding: "25px 28px",
                            backgroundColor: "#FFF",
                          }}
                        >
                          <Box>
                            <Typography
                              sx={{
                                fontWeight: 400,
                                fontSize: "14px",
                                lineHeight: "24px",
                              }}
                            >
                              {key + 1}. {item.question}{" "}
                              {Boolean(parseInt(item.is_required)) && (
                                <span style={{ color: "red" }}> * </span>
                              )}
                            </Typography>
                          </Box>

                          <Box>
                            {Boolean(
                              errors["answer"]?.[`_${item.id}`]?.required
                            ) && (
                              <Box>
                                <FormHelperText error>
                                  {errors["answer"]?.[`_${item.id}`]?.message}
                                </FormHelperText>
                              </Box>
                            )}
                          </Box>

                          {/* error display here */}
                          <Box>
                            {Boolean(
                              randomErrors?.[`${item.id}`]?.hasError
                            ) && (
                              <FormHelperText error>
                                {randomErrors?.[`${item.id}`]?.message}{" "}
                              </FormHelperText>
                            )}
                          </Box>

                          {/* question-short-answer */}
                          {item.type === "question-short-answer" && (
                            <Box mt={1}>
                              <Fc
                                name={`answer[_${item.id}][_${item.choices[0].id}]`}
                                control={control}
                                render={({ field }) => {
                                  return (
                                    <TreffasTextField
                                      {...field}
                                      value={field.value ?? ""}
                                      onChange={(e) => {
                                        console.log("e", e.target.value);
                                        setValue(
                                          `answer[_${item.id}][_${item.choices[0].id}]`,
                                          e.target.value
                                        );
                                      }}
                                      fullWidth
                                      sx={TreffasStyle.formInputLarge("input")}
                                      error={Boolean(
                                        errors["answer"]?.[`_${item.id}`]?.[
                                          `_${item.choices[0].id}`
                                        ]?.required
                                      )}
                                      helperText={
                                        errors["answer"]?.[`_${item.id}`]?.[
                                          `_${item.choices[0].id}`
                                        ]?.message
                                      }
                                    />
                                  );
                                }}
                              />
                            </Box>
                          )}

                          {/* question-one-answer */}
                          {item.type === "question-comment-essay" && (
                            <Box mt={1}>
                              <Fc
                                name={`answer[_${item.id}][_${item.choices[0].id}]`}
                                control={control}
                                render={({ field }) => (
                                  <TreffasTextField
                                    {...field}
                                    value={field.value ?? ""}
                                    fullWidth
                                    multiline
                                    minRows={3}
                                    sx={TreffasStyle.formInputLarge("textarea")}
                                    error={Boolean(
                                      errors["answer"]?.[`_${item.id}`]?.[
                                        `_${item.choices[0].id}`
                                      ]?.required
                                    )}
                                    helperText={
                                      errors["answer"]?.[`_${item.id}`]?.[
                                        `_${item.choices[0].id}`
                                      ]?.message
                                    }
                                  />
                                )}
                              />
                            </Box>
                          )}

                          {/* question-one-answer */}
                          {item.type === "question-one-answer" && (
                            <Box mx={5} mt={1}>
                              <Grid container spacing={"10px"}>
                                {item.choices.length > 0 &&
                                  item.choices.map((ch, chKey) => (
                                    <Grid item xs={12} md={12} key={chKey}>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Fc
                                          name={`answer[_${item.id}]`}
                                          control={control}
                                          render={({ field }) => {
                                            return (
                                              <>
                                                <input
                                                  {...field}
                                                  checked={
                                                    field?.value === ch.id
                                                  }
                                                  onChange={() => {
                                                    setValue(
                                                      `answer[_${item.id}]`,
                                                      ch.id
                                                    );
                                                  }}
                                                  type="radio"
                                                  id={`flexRadioDefault1dfdsf${ch.id}`}
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                  }}
                                                />
                                                <label
                                                  htmlFor={`flexRadioDefault1dfdsf${ch.id}`}
                                                  style={{
                                                    marginTop: "5px",
                                                    marginLeft: "5px",
                                                    fontWeight: 400,
                                                    fontSize: "14px",
                                                    lineHeight: "24px",
                                                  }}
                                                >
                                                  {ch.options}
                                                </label>
                                              </>
                                            );
                                          }}
                                        />
                                      </Box>
                                    </Grid>
                                  ))}
                              </Grid>
                            </Box>
                          )}

                          {/* question-multiple-answer */}
                          {item.type === "question-multiple-answer" && (
                            <Box mx={5} mt={1}>
                              <Grid container spacing={"10px"}>
                                {item.choices.length > 0 &&
                                  item.choices.map((ch, chKey) => (
                                    <Grid item xs={12} md={12} key={chKey}>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Fc
                                          name={`answer[_${item.id}]`}
                                          control={control}
                                          render={({ field }) => {
                                            return (
                                              <>
                                                <input
                                                  {...field}
                                                  checked={
                                                    field?.value
                                                      ? field?.value?.includes(
                                                          ch.id
                                                        )
                                                      : false
                                                  }
                                                  value={
                                                    field?.value
                                                      ? JSON.stringify(
                                                          field?.value
                                                        )
                                                      : ""
                                                  }
                                                  type="checkbox"
                                                  id={`flexCheckDefault45345${ch.id}`}
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                  }}
                                                  onChange={(event) => {
                                                    let valueForMultipleAnswer =
                                                      field?.value
                                                        ? field?.value
                                                        : [];

                                                    if (event.target.checked) {
                                                      if (
                                                        parseInt(
                                                          valueForMultipleAnswer.length +
                                                            1
                                                        ) >
                                                        parseInt(item.limits)
                                                      ) {
                                                        showSnackbar(
                                                          t(
                                                            "individual.booking.schedule.warning.survey_pick_limit",
                                                            {
                                                              limits:
                                                                item.limits,
                                                            }
                                                          ),
                                                          "error"
                                                        );
                                                      } else {
                                                        if (
                                                          valueForMultipleAnswer.includes(
                                                            ch.id
                                                          )
                                                        ) {
                                                          valueForMultipleAnswer =
                                                            valueForMultipleAnswer.filter(
                                                              (x) => x !== ch.id
                                                            );
                                                        } else {
                                                          valueForMultipleAnswer.push(
                                                            ch.id
                                                          );
                                                        }
                                                      }
                                                    } else {
                                                      valueForMultipleAnswer =
                                                        valueForMultipleAnswer.filter(
                                                          (x) => x !== ch.id
                                                        );
                                                    }

                                                    setValue(
                                                      `answer[_${item.id}]`,
                                                      valueForMultipleAnswer
                                                    );
                                                  }}
                                                />
                                                <label
                                                  htmlFor={`flexCheckDefault45345${ch.id}`}
                                                  style={{
                                                    marginTop: "5px",
                                                    marginLeft: "5px",
                                                    cursor: "pointer",
                                                    textTransform: "capitalize",
                                                    fontWeight: 400,
                                                    fontSize: "14px",
                                                    lineHeight: "24px",
                                                  }}
                                                >
                                                  {ch.options}
                                                </label>
                                              </>
                                            );
                                          }}
                                        />
                                      </Box>
                                    </Grid>
                                  ))}
                              </Grid>
                            </Box>
                          )}

                          {/* question-true-false */}
                          {item.type === "question-true-false" && (
                            <Box mx={5} mt={1}>
                              <Grid container spacing={"10px"}>
                                {item.choices.length > 0 &&
                                  item.choices.map((ch, chKey) => (
                                    <Grid item xs={12} md={12} key={chKey}>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Fc
                                          name={`answer[_${item.id}]`}
                                          control={control}
                                          render={({ field }) => {
                                            return (
                                              <>
                                                <input
                                                  {...field}
                                                  checked={
                                                    field?.value === ch.id
                                                  }
                                                  onChange={() => {
                                                    setValue(
                                                      `answer[_${item.id}]`,
                                                      ch.id
                                                    );
                                                  }}
                                                  type="radio"
                                                  id={`flexRadioDefault1dfdsf7788${ch.id}`}
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                  }}
                                                />
                                                <label
                                                  htmlFor={`flexRadioDefault1dfdsf7788${ch.id}`}
                                                  style={{
                                                    marginTop: "5px",
                                                    marginLeft: "5px",
                                                    fontWeight: 400,
                                                    fontSize: "14px",
                                                    lineHeight: "24px",
                                                  }}
                                                >
                                                  {ch.options}
                                                </label>
                                              </>
                                            );
                                          }}
                                        />
                                      </Box>
                                    </Grid>
                                  ))}
                              </Grid>
                            </Box>
                          )}

                          {/* question-yes-no */}
                          {item.type === "question-yes-no" && (
                            <Box mx={5} mt={1}>
                              <Grid container spacing={"10px"}>
                                {item.choices.length > 0 &&
                                  item.choices.map((ch, chKey) => (
                                    <Grid item xs={12} md={12} key={chKey}>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Fc
                                          name={`answer[_${item.id}]`}
                                          control={control}
                                          render={({ field }) => {
                                            return (
                                              <>
                                                <input
                                                  {...field}
                                                  checked={
                                                    field?.value === ch.id
                                                  }
                                                  onChange={() => {
                                                    setValue(
                                                      `answer[_${item.id}]`,
                                                      ch.id
                                                    );
                                                  }}
                                                  type="radio"
                                                  id={`flexRadioDefault1dfdsf7788df${ch.id}`}
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                  }}
                                                />
                                                <label
                                                  htmlFor={`flexRadioDefault1dfdsf7788df${ch.id}`}
                                                  style={{
                                                    marginTop: "5px",
                                                    marginLeft: "5px",
                                                    fontWeight: 400,
                                                    fontSize: "14px",
                                                    lineHeight: "24px",
                                                  }}
                                                >
                                                  {ch.options}
                                                </label>
                                              </>
                                            );
                                          }}
                                        />
                                      </Box>
                                    </Grid>
                                  ))}
                              </Grid>
                            </Box>
                          )}
                        </Box>
                      );
                    })}
                  </>
                ) : (
                  <Typography sx={{ color: "#838383" }}>
                    {t("survey.take.form.error.noquestion_found")}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Fragment>
        );
      })}
    </>
  );
};

export default Survey;
