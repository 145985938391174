import { Box, Container, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import IsMounted from "src/components/IsMounted";
import Constant from "src/utils/Constant";
import http from "src/utils/http";
import PowerdBy from "../../PowerdBy";
import { Users } from "src/context";

const RespondentsCopy = () => {
  const { surveyId, respondentId, surveySlug } = useParams();
  const mounted = IsMounted();
  const [surveyCopy, setSurveyCopy] = useState(null);
  const { mixpanel } = useContext(Users);

  const getSurveyCopy = React.useCallback(() => {
    http
      .get("/api/general/survey/respondents-copy-details", {
        params: {
          survey_id: surveyId,
          slug: surveySlug,
          respondent_id: respondentId,
        },
      })
      .then((response) => {
        if (mounted.current) {
          setSurveyCopy(response.data);
        }
      })
      .catch((err) => console.log("error:", err.message));
  }, [mounted, surveyId, surveySlug, respondentId]);

  React.useEffect(() => {
    mixpanel.track("Survey Respondent Copy", {
      location: window.location.href,
    });
    getSurveyCopy();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSurveyCopy]);

  return (
    <Box>
      <Box
        sx={{
          minHeight: "calc(100vh - 70px)", // 70px is the height og poweredby component
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Container
          maxWidth={"tp"}
          sx={{
            padding: "0px !important",
            marginTop: "90px",
            marginBottom: "80px",
          }}
        >
          {surveyCopy?.survey?.logo && (
            <Box
              sx={{
                mb: "40px",
              }}
            >
              <img
                alt="Treffas logo"
                src={Constant.imageUrl(
                  "coach/survey",
                  surveyCopy?.survey?.logo
                )}
                style={{
                  height: "100px",
                }}
              />
            </Box>
          )}

          <Box mb={"20px"}>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "28px",
                lineHeight: "46px",
                color: " #343544",
                textTransform: "capitalize",
              }}
            >
              {surveyCopy?.survey?.name}
            </Typography>
          </Box>

          <Box>
            {surveyCopy?.questions.map((item, key) => (
              <Box key={key}>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "16px",
                    color: "#343544",
                  }}
                >
                  {key + 1}. {item.question}
                </Typography>

                <Box mx={3}>
                  {item?.respondent_answers?.map((answer) => {
                    if (
                      item.question_type === 1 ||
                      item.question_type === 2 ||
                      item.question_type === 4 ||
                      item.question_type === 6
                    ) {
                      return (
                        <Box key={answer.id}>
                          <p> {answer.optionname.options}</p>
                        </Box>
                      );
                    } else {
                      return (
                        <Box key={answer.id}>
                          <p> {answer.answer_text}</p>
                        </Box>
                      );
                    }
                  })}
                </Box>
              </Box>
            ))}
          </Box>
        </Container>
      </Box>

      {/* powered by */}
      <Box>
        <PowerdBy />
      </Box>
    </Box>
  );
};

export default RespondentsCopy;
