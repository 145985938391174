import {
  Box,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import React from "react";
import { StaticDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import http from "src/utils/http";
import Constant from "src/utils/Constant";
import { useTranslation } from "react-i18next";
import StepperGuide from "src/components/StepperGuide";
import Booking from "src/utils/Booking";
import TimezonePicker from "../../timezone-picker";
import Timezone from "src/utils/Timezone";
import TreffasStyle from "src/utils/TreffasStyle";
import { ArrowDropDown } from "@mui/icons-material";
import TreffasTextField from "src/components/TreffasTextField";
import Helper from "src/utils/Helper";
import momentTimezone from "moment-timezone";

let dateFormat = "YYYY/MM/DD";

const DateAndTime = ({
  selectedTimezone,
  setSessionSelectedTime,
  setAvailableTime,
  selectedOffer,
  setSessionList,
  setBlockTimeOnSelectedDate,
  selectedDate,
  setSelectedDate,
  setError,
  clearErrors,
  calendarMaxDate,
  userDateFormat,
  stepperGuideLength,
  bookingInfo,
  setAllUpcommingAppt,
  nextButtonRef,
  coachDetails,
  setOverFlowTimeToRender,
  setSelectedTimezone,
  setUnderFlowTimeToRender,
  isUseTimeRecord,
  setIsUseTimeRecord,
  timeRecords,
  setTimeRecords,
  preparingSessionList,
  setPreparingSesisonList,
  setActiveStep,
}) => {
  const { t } = useTranslation();
  const appointmentInterval =
    selectedOffer?.custom_options?.interval ?? Constant.interval;

  const appointmentSlots = selectedOffer?.slots;

  const [timezoneDialog, setTimezoneDialog] = React.useState({ open: false });

  let listofOverFlowDates = [];
  let listofUnderFlowDates = [];

  let listofOverflow = {};
  let listofUnderflow = {};
  let listOfAvailableDates = {};

  const shouldDisabledDate = (date) => {
    let isWholeDayBlockedByCoach =
      Booking._dateandtime.isWholeDayBlockedByCoach(
        selectedOffer,
        date,
        coachDetails?.timezone_utc,
        selectedTimezone
      );

    if (isWholeDayBlockedByCoach) {
      return true; // calendar date is block by coach wholeday 00:00 to 23:59
    }

    let package_availability = Booking._dateandtime.getPackageAvailability(
      date,
      selectedOffer
    );

    // call fn to initialize listofOverFlowDates and listofUnderFlowDates variables
    Booking._dateandtime.generateSessionTime(
      package_availability,
      selectedOffer?.duration,
      date,
      selectedTimezone,
      coachDetails,
      appointmentInterval,
      listofOverFlowDates,
      listofOverflow,
      listofUnderFlowDates,
      listofUnderflow,
      listOfAvailableDates
    );

    let today = moment(date).format(dateFormat);
    let day = moment(date, "dddd").format("dddd");
    let daysEnabled = Booking._dateandtime.getDayEnableInPackage(
      date,
      selectedOffer
    );
    let dayIsEnable = Booking._dateandtime.isDayEnable(daysEnabled, day);
    // if isUseTimeRecord is true use states to match records if false use actual variables
    let hasOverflow = isUseTimeRecord
      ? timeRecords.listofOverFlowDates.includes(today.toString())
      : listofOverFlowDates.includes(today.toString());
    let hasUnderFlow = isUseTimeRecord
      ? timeRecords.listofUnderFlowDates.includes(today.toString())
      : listofUnderFlowDates.includes(today.toString());
    let hasSessions = isUseTimeRecord
      ? timeRecords.listOfAvailableDates[today]
      : listOfAvailableDates[today];

    if (hasOverflow) return false;
    if (hasUnderFlow) return false;
    if (!hasSessions && !hasOverflow && !hasUnderFlow) return true;
    if (!dayIsEnable) return false;
    return true;
  };

  const handleSelectedDate = async (date) => {
    if (preparingSessionList) return;
    setPreparingSesisonList(true);
    clearErrors();
    setSessionSelectedTime({});
    setSelectedDate(date);
    setIsUseTimeRecord(true);
    setActiveStep(1);
    // store prev records and use to rerender when back button is press
    setTimeRecords({
      listofOverFlowDates: listofOverFlowDates,
      listofUnderFlowDates: listofUnderFlowDates,
      listofOverflow: listofOverflow,
      listofUnderflow: listofUnderflow,
      listOfAvailableDates: listOfAvailableDates,
    });

    let today = moment(date).format(dateFormat);

    const gettodayAppt = await http.get(
      `/api/general/individual/offer/details/get-todays-offer-appts`,
      {
        params: {
          offer_id: selectedOffer?.id,
          organization_id: selectedOffer?.organization_id,
          user_id: selectedOffer?.user_id,
          selected_date: today,
        },
      }
    );

    const getallUpcomingAppt = await http.get(
      `/api/general/individual/offer/details/get-all-upcomming-appts`,
      {
        params: {
          offer_id: selectedOffer?.id,
          organization_id: selectedOffer?.organization_id,
          user_id: selectedOffer?.user_id,
          selected_date: today,
        },
      }
    );

    let events = getallUpcomingAppt.data?.events.filter(
      (x) => x?.extras?.appointment_id !== parseInt(bookingInfo?.id)
    );

    setAllUpcommingAppt({ events });

    if (gettodayAppt.data.length >= parseInt(appointmentSlots)) {
      setSessionList([]);
      setPreparingSesisonList(false);
      return;
    }

    setSessionList(
      listOfAvailableDates[today] ?? [] // if time has underflow get the yesterday data as start of availabeltime
    );
    setOverFlowTimeToRender(listofOverflow[today] ?? []);
    setUnderFlowTimeToRender(listofUnderflow[today] ?? []);
    setPreparingSesisonList(false);

    Booking._dateandtime.handleBlockedDate(
      date,
      selectedOffer,
      setBlockTimeOnSelectedDate
    );

    nextButtonRef.current.click();
  };

  const clientToday = momentTimezone(new Date(), Helper.zeroTimezone).tz(
    selectedTimezone
  );

  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "10px",
            pt: "58px",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "18px",
                lineHeight: "31px",
                alignItems: "center",
                color: "#060A2D",
              }}
            >
              {/* {t("booking.label.choose_date_and_time")} */}
              Choose Schedule
            </Typography>

            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "18px",
                color: "#878787",
                mt: "4px",
              }}
            >
              Select date and time for your {selectedOffer?.name} that works for
              you
            </Typography>
          </Box>

          <Box>
            <StepperGuide length={stepperGuideLength} activeLength={[1]} />
          </Box>
        </Box>
      </Box>

      <Box my={"20px"}>
        <Box mb={"10px"}>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "10px",
              lineHeight: "11px",
              letterSpacing: "0.07em",
              color: "#A1A1A1",
              textTransform: "uppercase",
            }}
          >
            {t("booking.label.select_timezone")}
          </Typography>
        </Box>

        <Box sx={{ px: "5px" }}>
          <Box>
            <TreffasTextField
              value={Timezone.getLabel(selectedTimezone) ?? ""}
              sx={TreffasStyle.formInputLarge("autocomplete")}
              placeholder={t("booking.placeholder.select_timezone")}
              fullWidth
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      size={"small"}
                      onClick={() => {
                        setTimezoneDialog({ open: true });
                        setSelectedDate(new Date());
                      }}
                    >
                      <ArrowDropDown />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Box>
              <TimezonePicker
                dialog={timezoneDialog}
                close={() => setTimezoneDialog({ open: false })}
                selectedTimezone={selectedTimezone}
                onChange={(data) => {
                  setSelectedTimezone(data);
                  setIsUseTimeRecord(false);
                  setOverFlowTimeToRender([]);
                  setUnderFlowTimeToRender([]);
                  setSessionList([]);
                  listofUnderflow = {};
                  listofOverflow = {};
                  listofOverFlowDates = [];
                  listofUnderFlowDates = [];
                  listOfAvailableDates = {};
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          mt: "48px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: { xs: "10px", md: "32px" },
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box className="new-static-calendar">
            <StaticDatePicker
              // disablePast
              minDate={
                new Date(moment(clientToday, "YYYY/MM/DD").format("YYYY/MM/DD"))
              }
              displayStaticWrapperAs="desktop"
              value={selectedDate ? moment(selectedDate, "YYYY/MM/DD") : null}
              shouldDisableDate={(date) => shouldDisabledDate(date)}
              onChange={(value) => handleSelectedDate(value)}
              onMonthChange={() => setIsUseTimeRecord(false)}
              renderInput={(params) => <TextField {...params} />}
              views={["day"]}
              inputFormat={userDateFormat}
              maxDate={
                new Date(
                  moment(calendarMaxDate, "YYYY/MM/DD").format("YYYY/MM/DD")
                )
              }
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DateAndTime;
