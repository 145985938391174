import { Box, Typography, Button, CircularProgress } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Logo from "src/components/Logo";
import SideBg from "../../SideBg";
import { useLocation } from "react-router-dom";
import http from "src/utils/http";
import IconGettingStarted from "src/components/svg-icons/IconGettingStarted";
import { useTranslation } from "react-i18next";
import { Users } from "src/context";
import { useSnackbarContext } from "src/context/SnackbarContext";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const VerificationConfirmation = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isReady, setIsReady] = useState(false);
  const searchParams = useQuery();
  const { mixpanel } = useContext(Users);

  const email = searchParams.get("email");
  const token = searchParams.get("token");

  const { showSnackbar } = useSnackbarContext();
  const [userData, setUserData] = useState(null);

  const { t } = useTranslation();

  const onGetStarted = () => {
    window.location = `/app/v2/calendar`;
  };

  const onConfirm = React.useCallback(() => {
    http
      .post("/api/oauth/registration/confirm-verification", {
        email,
        token: token,
      })
      .then(({ data }) => {
        if (data.message === "token-empty") {
          showSnackbar(
            t("registration.form.validation.invalid.verification-link-invalid"),
            "error"
          );
        }
        if (data.message === "token-expired") {
          showSnackbar(
            t(
              "registration.form.validation.invalid.verification-token-invalid"
            ),
            "error"
          );
        }

        if (data.message === "account-verified" || data.message === "success") {
          let msx =
            data.message === "account-verified"
              ? t("registration.form.validation.account-verified")
              : t("registration.form.validation.verification-success");

          localStorage.setItem(`accessToken`, data.access_token);
          localStorage.setItem(`__loginType`, "coach");
          showSnackbar(msx, "success");
          setIsReady(true);
          setUserData(data.auth_user);
        }
      })
      .catch((err) => {
        console.log(`error:`, err.message);
      })
      .finally(() => setIsLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, email]);

  useEffect(() => {
    mixpanel.track("Registration Email Confirmation Page", {
      location: window.location.href,
    });

    onConfirm();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onConfirm]);

  return (
    <>
      <Box
        sx={{
          minHeight: "calc(100vh - 0px)",
          display: { lg: "flex" },
          flexDirection: { lg: "row-reverse" },
        }}
      >
        <Box flexGrow={1}>
          <Box>
            <Box
              sx={{
                height: "80px",
                display: {
                  xs: "flex",
                  lg: "none",
                },
                alignItems: "center",
                pl: "99px",
                boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.04)",
              }}
            >
              <Logo />
            </Box>

            <Box
              sx={{
                display: {
                  xs: "block",
                },
                justifyContent: "center",
                alignItems: "center",
                px: "24px",
              }}
            >
              <Box mt={"127px"}>
                <Box textAlign={"center"}>
                  <IconGettingStarted />
                </Box>

                <Box mt={"42px"} textAlign={"center"}>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: "32px",
                      lineHeight: "46px",
                      textAlign: "center",
                      letterSpacing: "-0.01em",
                      color: "#191B1D",
                    }}
                  >
                    {t("registration.form.verification.confirmation.welcome")}{" "}
                    <Typography
                      variant={"caption"}
                      sx={{
                        fontWeight: 700,
                        fontSize: "32px",
                        lineHeight: "46px",
                        textAlign: "center",
                        letterSpacing: "-0.01em",
                        background:
                          "linear-gradient(to left, rgba(69, 60, 254, 1), rgba(24, 172, 254, 1), rgba(1, 115, 254, 1))",
                        backgroundClip: "text",
                        textFillColor: "transparent",
                        textTransform: "capitalize",
                      }}
                    >
                      {userData?.name}!
                    </Typography>
                  </Typography>
                </Box>

                <Box mt={"8px"} textAlign={"center"}>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "24px",
                      textAlign: "center",
                      color: "#6A6E91",
                    }}
                  >
                    {t(
                      "registration.form.verification.confirmation.description.first"
                    )}
                  </Typography>

                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "24px",
                      textAlign: "center",
                      color: "#6A6E91",
                    }}
                  >
                    {t(
                      "registration.form.verification.confirmation.description.second"
                    )}
                  </Typography>
                </Box>

                <Box mt={"64px"} textAlign={"center"} pb={"24px"}>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      textTransform: "inherit",
                      borderRadius: "8px",
                      fontSize: "14px",
                      padding: "10px",
                      lineHeight: "31px",
                      fontWeight: 400,
                      height: "48px",
                      width: "184px",
                    }}
                    disabled={!isReady || isLoading}
                    startIcon={
                      isLoading && (
                        <CircularProgress color="inherit" size={15} />
                      )
                    }
                    onClick={() => onGetStarted()}
                  >
                    {t("registration.form.button.get-started")}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <SideBg />
      </Box>
    </>
  );
};

export default VerificationConfirmation;
