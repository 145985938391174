const Plans = {
  list: [
    {
      name: "Free",
      value: "free",
      amount: 0,
      inclusive: [
        "Calendar connection",
        "Unlimited number of clients",
        "Unlimited bookings per month",
        "Create 1-on-1 event or package",
        "Single of multiple sessions",
        "Member support via email",
      ],
    },

    {
      name: "Professional",
      // value: "price_1NcLLDIGp19AoPBHt18WFAsl", // free
      value: "price_1NkjRNBMHviY1tXYAnkYXWgX", // live
      type: "monthly",
      amount: 19,
      inclusive: [
        "Everything in Free Plan + more…",
        "Create group events or packages",
        "Payments with Stripe & Paypal",
        "Connect to email marketing tools",
        "Embed scheduler into your website",
        "Priority Support",
      ],
    },

    {
      name: "Professional",
      // value: "price_1NcLMZIGp19AoPBHMSUAQuus", // free
      value: "price_1NkjSnBMHviY1tXYTpNNW6dd", // live
      type: "yearly",
      amount: 190,
      inclusive: [
        "Everything in Free Plan + more…",
        "Create group events or packages",
        "Payments with Stripe & Paypal",
        "Connect to email marketing tools",
        "Embed scheduler into your website",
        "Priority Support",
      ],
    },
  ],
};

export default Plans;
