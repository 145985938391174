import {
  Dialog,
  Typography,
  DialogContent,
  Box,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import { X } from "react-feather";
import { useTranslation } from "react-i18next";
import TreffasTextField from "src/components/TreffasTextField";
import IconCheck from "src/components/svg-icons/IconCheck";
import Timezone from "src/utils/Timezone";
import TreffasStyle from "src/utils/TreffasStyle";

const TimezonePicker = ({ dialog, close, selectedTimezone, onChange }) => {
  // const [search, setSearch] = useState(Timezone.getLabel(selectedTimezone));
  const [search, setSearch] = useState("");
  const { t } = useTranslation();

  const filteredTimezone = Timezone.list.filter((data) =>
    data.label.toLowerCase().includes(search?.toLowerCase())
  );

  return (
    <Dialog
      open={dialog.open}
      onClose={close}
      BackdropProps={{
        sx: {
          background: "rgba(0, 0, 0, 0.82)",
        },
      }}
      PaperProps={{
        sx: (themes) => ({
          [themes.breakpoints.up("sm")]: {
            width: { xs: "100%", sm: "544px" },
            background: "#FFFFFF",
            border: "1px solid #EFF0F7",
            boxShadow: "0px 5px 16px rgba(8, 15, 52, 0.06)",
            borderRadius: "16px",
          },
        }),
      }}
      scroll="body"
      fullWidth
    >
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontFamily: "Mukta",
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: "30px",
                lineHeight: "28px",
                color: "#19181B",
              }}
            >
              {t("individual.booking.schedule.select_timezone")}
            </Typography>
          </Box>

          <Box>
            <IconButton onClick={() => close()}>
              <X />
            </IconButton>
          </Box>
        </Box>

        <Box sx={{ mt: "32px" }}>
          <TreffasTextField
            sx={TreffasStyle.formInputLarge("input")}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            fullWidth
            placeholder={t(
              "individual.booking.schedule.placeholder.search_timezone"
            )}
          />
        </Box>

        <Box
          className="session-time-scroll"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "0px",
            height: { xs: "424px", sm: "424px" },
            overflowY: { xs: "auto", sm: "auto" },
            mt: "16px",
          }}
        >
          {filteredTimezone.map((data, key) => (
            <Box
              key={key}
              sx={{
                padding: "16px 12px 16px 8px",
                // minHeight: "53px",
                background: "#FFFFFF",
                borderBottom: "1px solid #F4F5F6",
                cursor: "pointer",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
              onClick={() => {
                onChange(data.value);
                close();
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "21px",
                    color:
                      data.value === selectedTimezone ? "#19181B" : "#6E757C",
                  }}
                >
                  {data.label}
                </Typography>
              </Box>

              {data.value === selectedTimezone && (
                <Box>
                  <IconCheck />
                </Box>
              )}
            </Box>
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default TimezonePicker;
