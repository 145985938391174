import {
  Backdrop,
  Box,
  ButtonBase,
  CircularProgress,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import GL from "src/assets/images/linkedin-logo.png";

import { useLinkedIn } from "react-linkedin-login-oauth2";
import Constant from "src/utils/Constant";
import http from "src/utils/http";
import { useTranslation } from "react-i18next";
import { useSnackbarContext } from "src/context/SnackbarContext";

const LinkedInRegistration = () => {
  const redirectURI = `${window.location.origin}/login/auth-linkedin`;
  const scopes = "r_liteprofile r_emailaddress";
  const [isProcess, setIsProcess] = useState(false);
  const { showSnackbar } = useSnackbarContext();
  const { t } = useTranslation();

  const { linkedInLogin } = useLinkedIn({
    clientId: Constant.linkedInClientId,
    redirectUri: redirectURI,
    scope: scopes,
    onSuccess: async (code) => {
      setIsProcess(true);
      http
        .post("/api/oauth/registration/linkedin-profile", {
          grant_type: "authorization_code",
          code: code,
          client_id: Constant.linkedInClientId,
          client_secret: Constant.linkedInClientSecret,
          redirect_uri: redirectURI,
        })
        .then((response) => {
          if (response.data.message === "success") {
            handleRegistration(response.data);
          }

          if (response.data.message === "error") {
            setIsProcess(false);
            showSnackbar(
              t("registration.form.validation.linkedin_error"),
              "error"
            );
          }
        })
        .catch((err) => console.log(`error:`, err.message));
    },
    onError: (error) => {
      console.log("error", error);
    },
  });

  const handleRegistration = (linkedInData) => {
    let name = `${linkedInData?.profile?.firstname} ${linkedInData?.profile?.lastname}`;
    http
      .post("/api/oauth/registration/create-account-and-direct-login", {
        organization: name?.trim()?.toLowerCase(),
        name: name,
        email: linkedInData?.profile?.email,
        firstname: linkedInData?.profile?.firstname,
        lastname: linkedInData?.profile?.lastname,
        timezone: "China Standard Time",
        timezone_utc: "Asia/Shanghai",
        currency: "USD",
        thru: "linkedIn",
      })
      .then(({ data }) => {
        if (data.message === "email-exist") {
          showSnackbar(
            t("registration.form.validation.email_not_available"),
            "error"
          );
        }

        if (data.message === "success") {
          localStorage.setItem(`accessToken`, data.access_token);
          localStorage.setItem(`__loginType`, "coach");
          window.location.href = `/app/v2/calendar`;
        }
      })
      .catch((err) => {
        console.log(`error:`, err.message);
      })
      .finally(() => setIsProcess(false));
  };

  return (
    <>
      <ButtonBase onClick={linkedInLogin}>
        <Box
          sx={{
            width: "190px",
            height: "40px",
            border: "1px solid #dadce0",
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <img
            src={GL}
            alt={"LinkedIn Logo"}
            style={{ height: "25px", width: "25px" }}
          />
          <Typography
            variant="caption"
            sx={{
              fontWeight: 500,
              fontSize: "14px",
              color: "#3c4043",
              lineHeight: "31px",
              fontFamily: "DM Sans",
            }}
          >
            {t("registration.form.button.linkedin")}
          </Typography>
        </Box>
      </ButtonBase>

      <Backdrop
        sx={{
          backgroundColor: "#333970",
          opacity: "0.8 !important",
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isProcess}
      >
        <CircularProgress color="inherit" size={25} />
      </Backdrop>
    </>
  );
};

export default LinkedInRegistration;
