import { Dialog, DialogContent } from "@mui/material";
import React, { useState } from "react";
import Booking from "./Booking";
import Embed from "./Embed";
import EmbedButton from "./EmbedButton";
import SocialShare from "./SocialShare";

const ShareDialog = ({ open, close, bookingURL, hasHeaderOption = false }) => {
  const [category, setCategory] = useState("booking"); //booking, embed, share, button

  const onChangeCategory = (category) => {
    setCategory(category);
  };

  return (
    <Dialog
      open={open}
      onClose={() => console.log("uncloseable")}
      BackdropProps={{
        sx: {
          background: "#060a2dcc",
        },
      }}
      PaperProps={{
        sx: {
          width: { xs: "100%", sm: "480px" },
          borderRadius: "12px",
        },
      }}
      scroll="body"
    >
      <DialogContent sx={{ p: 0 }}>
        {category === "booking" && (
          <Booking
            bookingURL={bookingURL}
            close={close}
            onChangeCategory={onChangeCategory}
          />
        )}

        {category === "embed" && (
          <Embed
            bookingURL={bookingURL}
            close={close}
            onChangeCategory={onChangeCategory}
            hasHeaderOption={hasHeaderOption}
          />
        )}

        {category === "share" && (
          <SocialShare
            bookingURL={bookingURL}
            close={close}
            onChangeCategory={onChangeCategory}
          />
        )}

        {category === "button" && (
          <EmbedButton
            bookingURL={bookingURL}
            close={close}
            onChangeCategory={onChangeCategory}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ShareDialog;
