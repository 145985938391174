import { Card, CardContent, Typography, CardMedia } from "@mui/material";
import React from "react";
import Helper from "src/utils/Helper";
import Pdf from "./Pdf";
import Constant from "src/utils/Constant";
import Docx from "./Docx";
import General from "./General";
import Excel from "./Excel";
import Audio from "./Audio";
import Video from "./Video";
import Zip from "./Zip";

const CardWithThumbnail = ({
  file,
  location = "packages/attachments",
  isCustomize = false,
  customsize = {
    width: "218px",
    height: "100px",
  },
}) => {
  let name = file?.filename ?? file.name;
  let size = file?.extras?.size ?? file.size;
  let clickable = file?.original_filename ? true : false;

  return (
    <Card
      sx={{
        width: { xs: "100%", md: isCustomize ? customsize.width : "218px" },
        borderRadius: "8px",
      }}
      variant={"outlined"}
    >
      <CardHeader
        file={file}
        name={name}
        location={location}
        isCustomize={isCustomize}
        customsize={customsize}
      />
      <CardContent
        sx={{
          backgroundColor: "#F4F5F6",
          padding: isCustomize ? "8px !important" : "12px 16px",
        }}
      >
        <Typography
          sx={{
            color: clickable ? "#0173fe" : "#353A3F",
            cursor: clickable ? "pointer" : "default",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "normal",
            textDecorationLine: clickable ? "underline" : "none",
          }}
          noWrap
          onClick={() => {
            if (!clickable) return false;
            let source = Constant.getFileInStorage(location, file?.filename);
            window.open(source, "_blank");
          }}
        >
          {name}
        </Typography>

        {!isCustomize && (
          <Typography
            sx={{
              color: "#6E757C",
              fontSize: "10px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
            }}
          >
            {Helper.fileSize(size)} MB
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default CardWithThumbnail;

const CardHeader = ({ file, name, location, isCustomize, customsize }) => {
  let isUplaoded = file?.original_filename ? true : false;
  let isImage = Helper.isImageFile(name);
  let isPdf = Helper.fileExtension(name) === "pdf" ? true : false;
  let isMSWord = Helper.isMSWordFile(name);
  let isMSExcel = Helper.isMSExcelFile(name);
  let isAudio = Helper.isAudioFile(name);
  let isVideo = Helper.isVideoFile(name);
  let isZip = Helper.isZipFile(name);

  let thumbnail = isUplaoded
    ? Constant.getFileInStorage(location, file.filename)
    : Helper.generateThumbnail(file);

  let source = isUplaoded
    ? Constant.getFileInStorage(location, file.filename)
    : file;

  if (isPdf) {
    return (
      <Pdf file={source} isCustomize={isCustomize} customsize={customsize} />
    );
  }

  if (isMSWord) {
    return <Docx isCustomize={isCustomize} customsize={customsize} />;
  }

  if (isMSExcel) {
    return <Excel isCustomize={isCustomize} customsize={customsize} />;
  }

  if (isAudio) {
    return <Audio isCustomize={isCustomize} customsize={customsize} />;
  }

  if (isVideo) {
    return <Video isCustomize={isCustomize} customsize={customsize} />;
  }

  if (isZip) {
    return <Zip isCustomize={isCustomize} customsize={customsize} />;
  }

  if (isImage) {
    return (
      <CardMedia
        sx={{
          height: isCustomize ? customsize.height : 110,
        }}
        image={thumbnail}
        component="img"
        title="Thumbnail"
      />
    );
  }

  return <General isCustomize={isCustomize} customsize={customsize} />;
};
