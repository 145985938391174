import {
  Box,
  Typography,
  TextField,
  CircularProgress,
  InputAdornment,
  IconButton,
  FormHelperText,
} from "@mui/material";
import React, { useState } from "react";
import TreffasStyle from "src/utils/TreffasStyle";
import { StaticDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { useParams } from "react-router-dom";
import http from "src/utils/http";
import Constant from "src/utils/Constant";
import { useTranslation } from "react-i18next";
import Timezone from "src/utils/Timezone";
import StepperGuide from "src/components/StepperGuide";
import TreffasTextField from "src/components/TreffasTextField";
import { ArrowDropDown } from "@mui/icons-material";
import TimezonePicker from "src/views/general/timezone-picker";
import RenderTimeSlots from "../RenderTimeSlots";
import Booking from "src/utils/Booking";
import Helper from "src/utils/Helper";
import momentTimezone from "moment-timezone";

let dateFormat = "YYYY/MM/DD";

const DateAndTime = ({
  errors,
  setSelectedTimezone,
  selectedTimezone,
  sessionList,
  selectedSessionTime,
  setSessionSelectedTime,
  selectedOffer,
  setSessionList,
  setBlockTimeOnSelectedDate,
  appForThisOffer,
  blockTimeOnSelectedDate,
  selectedDate,
  setSelectedDate,
  setError,
  clearErrors,
  nextButtonRef,
  calendarMaxDate,
  userdateFormat,
  userTimeFormat,
  userMinimumBookingNotice,
  stepperGuideLength,
  coachDetails,
  overFlowTimeToRender,
  setOverFlowTimeToRender,
  underFlowTimeToRender,
  setUnderFlowTimeToRender,
  isUseTimeRecord,
  setIsUseTimeRecord,
  timeRecords,
  setTimeRecords,
  allUpcommingAppt,
  setAllUpcommingAppt,
}) => {
  const { t } = useTranslation();
  const { userId, offerId, organizationId } = useParams();
  const [preparingSessionList, setPreparingSesisonList] = useState(false);
  const [timeHover, setTimeHover] = useState(null);

  const appointmentInterval =
    selectedOffer?.custom_options?.interval ?? Constant.interval;

  const appointmentSlots = selectedOffer?.slots;

  const [timezoneDialog, setTimezoneDialog] = useState({
    open: false,
  });

  const [isTimezoneChange, setIsTimezoneChange] = useState(false);

  let listofOverFlowDates = [];
  let listofUnderFlowDates = [];

  let listofOverflow = {};
  let listofUnderflow = {};
  let listOfAvailableDates = {};

  const shouldDisabledDate = (date) => {
    let isWholeDayBlockedByCoach =
      Booking._dateandtime.isWholeDayBlockedByCoach(
        selectedOffer,
        date,
        coachDetails?.timezone_utc,
        selectedTimezone
      );

    if (isWholeDayBlockedByCoach) {
      return true; // calendar date is block by coach wholeday 00:00 to 23:59
    }

    let package_availability = Booking._dateandtime.getPackageAvailability(
      date,
      selectedOffer
    );

    // call fn to initialize listofOverFlowDates and listofUnderFlowDates variables
    Booking._dateandtime.generateSessionTime(
      package_availability,
      selectedOffer?.duration,
      date,
      selectedTimezone,
      coachDetails,
      appointmentInterval,
      listofOverFlowDates,
      listofOverflow,
      listofUnderFlowDates,
      listofUnderflow,
      listOfAvailableDates
    );

    let today = moment(date).format(dateFormat);
    let day = moment(date, "dddd").format("dddd");
    let daysEnabled = Booking._dateandtime.getDayEnableInPackage(
      date,
      selectedOffer
    );
    let dayIsEnable = Booking._dateandtime.isDayEnable(daysEnabled, day);
    // if isUseTimeRecord is true use states to match records if false use actual variables
    let hasOverflow = isUseTimeRecord
      ? timeRecords.listofOverFlowDates.includes(today.toString())
      : listofOverFlowDates.includes(today.toString());
    let hasUnderFlow = isUseTimeRecord
      ? timeRecords.listofUnderFlowDates.includes(today.toString())
      : listofUnderFlowDates.includes(today.toString());
    let hasSessions = isUseTimeRecord
      ? timeRecords.listOfAvailableDates[today]
      : listOfAvailableDates[today];

    if (hasOverflow) return false;
    if (hasUnderFlow) return false;
    if (!hasSessions && !hasOverflow && !hasUnderFlow) return true;
    if (!dayIsEnable) return false;
    return true;
  };

  const handleSelectedDate = async (date) => {
    if (preparingSessionList) return;
    setPreparingSesisonList(true);
    clearErrors();
    setSessionSelectedTime({});
    setSelectedDate(date);
    setIsTimezoneChange(false);
    setIsUseTimeRecord(false);

    let today = moment(date).format(dateFormat);

    const gettodayAppt = await http.get(
      `/api/general/individual/offer/details/get-todays-offer-appts`,
      {
        params: {
          offer_id: offerId,
          organization_id: organizationId,
          user_id: userId,
          selected_date: today,
        },
      }
    );

    const getallUpcomingAppt = await http.get(
      `/api/general/individual/offer/details/get-all-upcomming-appts`,
      {
        params: {
          offer_id: offerId,
          organization_id: organizationId,
          user_id: userId,
          selected_date: today,
        },
      }
    );

    // if (getallUpcomingAppt.data.length > 0) {
    setAllUpcommingAppt(getallUpcomingAppt.data);
    // }

    if (gettodayAppt.data.length >= parseInt(appointmentSlots)) {
      setSessionList([]);
      setPreparingSesisonList(false);
      return;
    }

    setSessionList(
      listOfAvailableDates[today] ?? [] // if time has underflow get the yesterday data as start of availabeltime
    );
    setOverFlowTimeToRender(listofOverflow[today] ?? []);
    setUnderFlowTimeToRender(listofUnderflow[today] ?? []);
    setPreparingSesisonList(false);

    Booking._dateandtime.handleBlockedDate(
      date,
      selectedOffer,
      setBlockTimeOnSelectedDate
    );
  };

  const handleSelectedTime = async (time) => {
    setIsUseTimeRecord(true);
    // store prev records and use to rerender when back button is press
    setTimeRecords({
      listofOverFlowDates: listofOverFlowDates,
      listofUnderFlowDates: listofUnderFlowDates,
      listofOverflow: listofOverflow,
      listofUnderflow: listofUnderflow,
      listOfAvailableDates: listOfAvailableDates,
    });

    return Booking._dateandtime.handleSelectedTime(
      time,
      userMinimumBookingNotice,
      userdateFormat,
      userTimeFormat,
      setSessionSelectedTime,
      nextButtonRef,
      setError,
      t
    );
  };

  const filterList = (list) => {
    const coachTimezone = coachDetails?.timezone_utc;
    return Booking._dateandtime.filterList(
      list,
      blockTimeOnSelectedDate,
      appForThisOffer,
      allUpcommingAppt,
      selectedOffer,
      selectedTimezone,
      selectedDate,
      coachTimezone
    );
  };

  const ___filteredList = [
    ...overFlowTimeToRender,
    ...sessionList,
    ...underFlowTimeToRender,
  ];

  const filteredList = filterList(___filteredList);

  const clientToday = momentTimezone(new Date(), Helper.zeroTimezone).tz(
    selectedTimezone
  );

  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "10px",
            pt: "58px",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "18px",
                lineHeight: "31px",
                alignItems: "center",
                color: "#060A2D",
              }}
            >
              {t("individual.booking.schedule.choose_schedule")}
            </Typography>

            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "18px",
                color: "#878787",
                mt: "4px",
              }}
            >
              {t("individual.booking.schedule.choose_schedule.description", {
                package: selectedOffer?.name,
              })}
            </Typography>
          </Box>

          <Box>
            <StepperGuide length={stepperGuideLength} activeLength={[1]} />
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          mt: "48px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: { xs: "10px", md: "32px" },
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box className="new-static-calendar hide-selected-button">
            <StaticDatePicker
              // disablePast
              minDate={
                new Date(moment(clientToday, "YYYY/MM/DD").format("YYYY/MM/DD"))
              }
              displayStaticWrapperAs="desktop"
              value={selectedDate ? moment(selectedDate, dateFormat) : null}
              shouldDisableDate={(date) => shouldDisabledDate(date)}
              onChange={(value) => handleSelectedDate(value)}
              renderInput={(params) => <TextField {...params} />}
              views={["day"]}
              inputFormat={userdateFormat}
              maxDate={
                new Date(moment(calendarMaxDate, dateFormat).format(dateFormat))
              }
              showDaysOutsideCurrentMonth
            />
          </Box>

          {/* availabitlity */}
          <Box
            style={{
              width: "100%",
            }}
          >
            <Box mb={"20px"}>
              <Box mb={"10px"}>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: "10px",
                    lineHeight: "11px",
                    letterSpacing: "0.07em",
                    color: "#A1A1A1",
                    textTransform: "uppercase",
                  }}
                >
                  {t("booking.label.select_timezone")}
                </Typography>
              </Box>

              <Box sx={{ px: "5px" }}>
                <Box>
                  <TreffasTextField
                    value={Timezone.getLabel(selectedTimezone) ?? ""}
                    sx={TreffasStyle.formInputLarge("autocomplete")}
                    placeholder={t("booking.placeholder.select_timezone")}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            size={"small"}
                            onClick={() =>
                              setTimezoneDialog({
                                open: true,
                              })
                            }
                          >
                            <ArrowDropDown />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Box>
                    <TimezonePicker
                      dialog={timezoneDialog}
                      close={() =>
                        setTimezoneDialog({
                          open: false,
                        })
                      }
                      selectedTimezone={selectedTimezone}
                      onChange={(data) => {
                        setSelectedTimezone(data);
                        setIsTimezoneChange(true);
                        setOverFlowTimeToRender([]);
                        setUnderFlowTimeToRender([]);
                        setSessionList([]);
                        listofUnderflow = {};
                        listofOverflow = {};
                        listofOverFlowDates = [];
                        listofUnderFlowDates = [];
                        listOfAvailableDates = {};
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box>
              <Box mb={"10px"}>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: "10px",
                    lineHeight: "11px",
                    letterSpacing: "0.07em",
                    color: "#A1A1A1",
                    textTransform: "uppercase",
                  }}
                >
                  {t("booking.placeholder.time_slots")}
                </Typography>

                {Boolean(errors?.time) && (
                  <FormHelperText error>
                    {" "}
                    {errors?.time?.message}{" "}
                  </FormHelperText>
                )}
              </Box>

              {selectedDate === null || selectedDate === "" ? (
                <NoAvailableTime
                  message={t("booking.error.required.select_date")}
                />
              ) : (
                <Box
                  className="session-time-scroll"
                  sx={{
                    height: "338px",
                    overflowY: "auto",
                    width: "392px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "10px",
                    }}
                  >
                    {preparingSessionList ? (
                      <Box
                        sx={{
                          width: "100%",
                          textAlign: "center",
                          mt: "10px",
                        }}
                      >
                        <CircularProgress size={20} />
                      </Box>
                    ) : filteredList?.length > 0 ? (
                      filteredList?.map((time, key) => {
                        return (
                          <Box
                            sx={{
                              width: "119px",
                            }}
                            key={key}
                          >
                            <RenderTimeSlots
                              selectedSessionTime={selectedSessionTime}
                              time={time}
                              handleSelectedTime={handleSelectedTime}
                              timeHover={timeHover}
                              setTimeHover={setTimeHover}
                              userTimeFormat={userTimeFormat}
                              _key={key}
                            />
                          </Box>
                        );
                      })
                    ) : (
                      <NoAvailableTime
                        message={
                          isTimezoneChange
                            ? t(
                                "individual.booking.schedule.choose_schedule.select_date_again"
                              )
                            : t(
                                "booking.error.required.no_time_on_selected_date"
                              )
                        }
                      />
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DateAndTime;

const NoAvailableTime = ({ message }) => (
  <Box
    sx={{
      height: "338px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#F2F2F2",
      width: "100%",
      borderRadius: "8px",
    }}
  >
    <Typography
      sx={{
        fontWeight: 700,
        fontSize: "10px",
        lineHeight: "11px",
        letterSpacing: "0.07em",
        color: "#A1A1A1",
        textTransform: "uppercase",
        textAlign: "center",
      }}
    >
      {message}
    </Typography>
  </Box>
);
