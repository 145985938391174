import moment from "moment";
import momentTimezone from "moment-timezone";
import useMediaQuery from "@mui/material/useMediaQuery";
import Constant from "./Constant";

let xtoday = moment(new Date()).format("YYYY-MM-DD");
let zeroTimezone = "UTC"; // utc +00:00

const Helper = {
  zeroTimezone: "UTC",
  linkify: (text) => {
    var urlRegex =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi; //eslint-disable-line

    return text.replace(urlRegex, function (url) {
      // return '<a href="' + url + ">" + url + "</a>";
      return `<a href='${url}'> ${url} </a>`;
    });
  },

  sortBreakOffTime: (breakOffTime) => {
    return breakOffTime.sort((ad, bd) => {
      return (
        new Date(`${xtoday} ${ad.time_start}`) -
        new Date(`${xtoday} ${bd.time_start}`)
      );
    });
  },

  convertBookingTimeToUTC: (date, clientTimezone) => {
    let clientTime = momentTimezone.tz(date, clientTimezone);
    return clientTime.clone().tz(zeroTimezone);
  },

  convertTimeToUTC: (date) => {
    return momentTimezone.tz(date, zeroTimezone);
  },

  convertTimeToClientTimezone: (date, clientTimezoneUTC) => {
    return Helper.convertTimeToUTC(date).clone().tz(clientTimezoneUTC);
  },

  convertTimeToCoachTimezone: (date, clientTimezoneUTC, coachTimezoneUTC) => {
    return Helper.convertTimeToClientTimezone(date, clientTimezoneUTC)
      .clone()
      .tz(coachTimezoneUTC);
  },

  packageSurveyViewType: [
    {
      value: "view-first-calendar",
      label: "package.label.survey.type.first",
      description: "package.label.booking_to_survey",
    },
    {
      value: "view-first-survey",
      label: "package.label.survey.type.second",
      description: "package.label.survey_to_booking",
    },
  ],

  packagePaymentFlow: [
    {
      label: "package.flow.label.payment_flow.view_first_calendar",
      description:
        "package.flow.label.payment_flow.view_first_calendar.description",
      value: "view-first-calendar",
    },
    {
      label: "package.flow.label.payment_flow.view_first_payment",
      description:
        "package.flow.label.payment_flow.view_first_payment.description",
      value: "view-first-payment",
    },
  ],

  IsMobile: () => {
    return useMediaQuery((theme) => theme.breakpoints.down("sm"));
  },

  IsTablet: () => {
    return useMediaQuery((theme) => theme.breakpoints.down("md"));
  },

  blobDownloader: (data, type, filename) => {
    const blob = new Blob([data], { type });
    const url = URL.createObjectURL(blob); // Create a URL for the exported file
    const link = document.createElement("a"); // Create a temporary link element
    link.href = url;
    link.download = filename;
    document.body.appendChild(link); // Append the link to the document body and click it to trigger the download
    link.click();
    document.body.removeChild(link); // Clean up by removing the temporary link and revoking the URL
    URL.revokeObjectURL(url);
  },

  isArray: (data) => {
    return Array.isArray(data);
  },

  fileSize: (size) => {
    const fileSizeInMB = size / (1024 * 1024); // Conversion from bytes to megabytes
    return fileSizeInMB.toFixed(2); // Limiting the result to two decimal places
  },

  fileExtension: (name) => {
    const parts = name?.split(".");
    if (parts?.length > 1) {
      return parts?.pop();
    }

    return "unknown";
  },

  isImageFile: (name) => {
    return [".jpg", ".jpeg", ".png", ".gif", ".bmp"]?.includes(
      `.${name.split(".").pop()}`
    );
  },

  isMSWordFile: (name) => {
    return [".doc", ".docx"].includes(`.${name.split(".").pop()}`);
  },

  isMSExcelFile: (name) => {
    return [".xls", ".xlsx"].includes(`.${name.split(".").pop()}`);
  },

  isAudioFile: (name) => {
    return [".mp3", ".wav"].includes(`.${name.split(".").pop()}`);
  },
  isVideoFile: (name) => {
    return [".mp4", ".avi"].includes(`.${name.split(".").pop()}`);
  },

  isPdf: (name) => {
    return [".pdf"].includes(`.${name.split(".").pop()}`);
  },

  isZipFile: (name) => {
    return [".rar", ".zip"].includes(`.${name.split(".").pop()}`);
  },

  isMSPowerPoint: (name) => {
    return [".ppt", ".pptx"].includes(`.${name.split(".").pop()}`);
  },

  isCsv: (name) => {
    return [".csv"].includes(`.${name.split(".").pop()}`);
  },

  isUrl: (text) => {
    const urlRegex = /^(?:http|https?):\/\/[\w.-]+\.\w{2,}(?:\/\S*)?$/;
    return urlRegex.test(text);
  },

  generateThumbnail: (file) => {
    return URL?.createObjectURL(file);
  },

  isEmailValid: (email) => {
    // Regular expression for a basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Test the email against the regular expression
    return emailRegex.test(email);
  },

  generateRandomString(length = 5) {
    const characters =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let result = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }

    return result;
  },

  resizeImage(base64Str) {
    return new Promise((resolve) => {
      let img = new Image();
      img.src = base64Str;
      img.onload = () => {
        let canvas = document.createElement("canvas");
        const MAX_WIDTH = 200;
        const MAX_HEIGHT = 200;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL());
      };
    });
  },

  numberWithCommas(number, currency = "USD") {
    let currencySymbol = Constant.getCurrency(currency).symbols;
    return (
      currencySymbol +
      Number(number).toLocaleString("en-US", { maximumFractionDigits: 2 })
    );
  },

  isNumber(value) {
    return !isNaN(value);
  },

  numberOfRows: [1, 5, 10, 15, 25, 50, 100],

  createSlug(text) {
    const specialCharacters = [
      "/",
      ":",
      "@",
      "#",
      "$",
      "%",
      "^",
      "&",
      "*",
      "(",
      ")",
      "+",
      "=",
      "[",
      "]",
      "{",
      "}",
      ",",
      ".",
      "<",
      ">",
      "?",
      ";",
      "'",
      '"',
      "\\",
      "|",
      "`",
      "~",
      "!",
      "_",
      " ",
    ];

    // Replace special characters with underscores and convert to lowercase
    let cleanedString = text.toLowerCase();
    specialCharacters.forEach((char) => {
      cleanedString = cleanedString.split(char).join("_");
    });

    return cleanedString;
  },

  isEmpty(value) {
    let val = value?.toString() ?? "";

    return val.trim() === "" || val.length === 0;
  },

  byteToSize(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  },

  capitalizeWord(str) {
    return str
      .split(" ") // Split the text into an array of words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
      .join(" ");
  },
};

export default Helper;
