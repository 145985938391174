import DefaultImg from "src/assets/images/survies/themes/default.png";
import MidnightGreenImg from "src/assets/images/survies/themes/midnight_green.png";
import MustardImg from "src/assets/images/survies/themes/mustard.png";
import WoodImg from "src/assets/images/survies/themes/wood.png";

const Survies = {
  themes: [
    {
      name: "Default",
      translated: "survies.label.settings.themes.default",
      value: "default",
      thumbnail: DefaultImg,
      configuration: {
        color: "#0173FE",
        border: "#F4F5F6",
        answer_bg_color: "transparent",
        answer_border: "#EAECEF",
        radio_border: "#A0A6AB",
        nps_border: "#EAECEF",

        background: "#FFF",
        text: {
          selected: "#FFF",
        },
        hover: {
          color: "#EAECEF",
        },
      },
    },

    {
      name: "Mustard",
      translated: "survies.label.settings.themes.mustard",
      value: "mustard",
      thumbnail: MustardImg,
      configuration: {
        color: "#E4D449",
        border: "#F7F3D4",
        answer_bg_color: "transparent",
        answer_border: "#EDE3B6",
        radio_border: "#DBCD51",
        nps_border: "#F1EBB1",

        background: "#FFFDEB",
        text: {
          selected: "#212529",
        },
        hover: {
          color: "#F1EBB1",
        },
      },
    },

    {
      name: "MidnightGreen",
      translated: "survies.label.settings.themes.midnightgreen",

      value: "midnight_green",
      thumbnail: MidnightGreenImg,
      configuration: {
        color: "#226169",
        border: "#C8F3F9",

        answer_bg_color: "#DBFAFD",
        answer_border: "#9BE8F3",
        radio_border: "#087C87",
        nps_border: "#9BE8F3",

        background: "#E7FCFE",
        text: {
          selected: "#FFF",
        },
        hover: {
          color: "#A5EAF3",
        },
        nps_background: "#DBFAFD",
      },
    },

    {
      name: "Wood",
      translated: "survies.label.settings.themes.wood",

      value: "wood",
      thumbnail: WoodImg,
      configuration: {
        color: "#906A3E",
        border: "#F2EFE3",
        answer_bg_color: "transparent",
        answer_border: "#EEE9D8",
        radio_border: "#CEBA8D",
        nps_border: "#EEE9D8",
        background: "#FCFBF7",
        text: {
          selected: "#FFF",
        },
        hover: {
          color: "#EDE7D4",
        },
      },
    },
  ],

  moreColors: [
    "#FFEEDD",
    "#8EECF5",
    "#FFCAD4",
    "#99C1DE",
    "#BCD4E6",
    "#D6E2E9",
    "#FCD5CE",
    "#FAE1DD",
    "#F8EDEB",
    "#E8E8E4",
    "#D8E2DC",
    "#ECE4DB",
    "#FFE5D9",
    "#FFD7BA",
    "#D9D9D9",
    "#EAE4E9",
    "#FFF1E6",
    "#FFD991",
    "#FAD2E1",
    "#E2ECE9",
    "#BEE1E6",
    "#F0EFEB",
    "#DFE7FD",
    "#CDDAFD",
    "#F8EDEB",
    "#EDF2FB",
    "#F9E5D8",
    "#E2EAFC",
    "#F9DCC4",
    "#D7E3FC",
    "#EEEAE6",
    "#FFD7BA",
    "#ECE4DB",
    "#B7FFD8",
    "#B8E0D2",
    "#DEC0F1",
    "#FFE69E",
    "#FCD5CE",
    "#C2E2EA",
    "#E2ECF2",
    "#E9FFFB",
    "#FFFEDE",
    "#FCFBF7",
    "#E7FCFF",
    "#FCFBF7",
  ],

  getTheme: (theme) => {
    return Survies.themes.find((x) => x.value === theme);
  },
};

export default Survies;
