import { Box, Button, Container, Typography } from "@mui/material";
import React, { useState, useEffect, useCallback, useRef } from "react";
import * as Yup from "yup";
import http from "src/utils/http";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import PageNotFound from "src/components/PageNotFound";
import momentTimezone from "moment-timezone";
import { useTranslation } from "react-i18next";
import LoadingScreen from "src/components/LoadingScreen";
import BookingWarningDialog from "./BookingWarningDialog";
import Page from "src/components/Page";
import Helper from "src/utils/Helper";
import Timezone from "src/utils/Timezone";
import TakeSurveyForm from "./take-survey";
import RenderBooking from "./RenderBooking";
import BookingHeader from "src/views/general/individual-booking/schedule/booking-header";
import CoachHeader from "src/views/general/individual-booking/schedule/header";
import PowerdBy from "src/views/general/PowerdBy";
import Booking from "src/utils/Booking";
import { useSnackbarContext } from "src/context/SnackbarContext";

const steps = [
  {
    key: 1,
    label: "Choose Date and Time",
    code: "datetime",
  },

  {
    key: 2,
    label: "Choose Date and Time",
    code: "datetimezone",
  },

  {
    key: 3,
    label: "Client Information",
    code: "information",
  },

  {
    key: 4,
    label: "Survey",
    code: "survey",
  },
];

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const StepperPaidBookingMobile = () => {
  const searchParams = useQuery();
  const session_slug = searchParams.get("session_slug");
  const client_id = searchParams.get("client_id");
  const payment_type = searchParams.get("payment_type");
  const methods = searchParams.get("methods");

  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = steps.length;
  const { userId, offerId, organizationId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedOffer, setSelectedOffer] = React.useState(null);
  const { t } = useTranslation();
  /** container of selected date match in block date **/
  // subtract current date with 1 days to trigger auto onchange on date picker //
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedSessionTime, setSessionSelectedTime] = useState({});
  const [blockTimeOnSelectedDate, setBlockTimeOnSelectedDate] = useState({});
  const [sessionList, setSessionList] = useState([]);

  const [selectedPaymentMethod] = useState(null);
  const [isProcess, setIsProcess] = useState(false);
  // initialize default timezone by ip address
  let zone = momentTimezone.tz.guess();

  const [selectedTimezone, setSelectedTimezone] = useState(zone);

  const eventColor = "#ff4f00";

  const [coachDetails, setCoachDetails] = useState(null);

  const [clientDetails, setClientDetails] = useState(null);
  const [clientPaymentDetails, setClientPaymentDetails] = useState(null);
  const [isBookingPaid, setIsBookingPaid] = useState(false);

  const [appForThisOffer, setAppForThisOffer] = useState([]);

  const { showSnackbar } = useSnackbarContext();

  const nextButtonRef = useRef(null);

  const [randomState, setRandomState] = useState({});
  const [randomErrors, setRandomErrors] = useState({});

  const [formValues, setFormValues] = useState({});
  const [hasPending, setHasPending] = useState(false);

  const submitButtonRef = useRef();

  const [extraFieldSelectedFiles, setExtraFieldSelectedFiles] = useState({});

  const [overFlowTimeToRender, setOverFlowTimeToRender] = useState([]);
  const [underFlowTimeToRender, setUnderFlowTimeToRender] = useState([]);

  const [isUseTimeRecord, setIsUseTimeRecord] = useState(false);
  const [timeRecords, setTimeRecords] = useState({
    listofOverFlowDates: [],
    listofUnderFlowDates: [],
    listofOverflow: {},
    listofUnderflow: {},
    listOfAvailableDates: {},
  });

  const [surveyHasAnswered, setSurveyHasAnswered] = useState(false);
  const navigate = useNavigate();

  const [preparingSessionList, setPreparingSesisonList] = useState(false);
  const [allUpcommingAppt, setAllUpcommingAppt] = useState([]);

  const Schema = Yup.object({
    firstname: Yup.string().required(t("booking.error.required.firstname")),
    lastname: Yup.string().required(t("booking.error.required.lastname")),
    // mobile: Yup.string().required(t("booking.error.required.mobile")),
    email: Yup.string()
      .email(t("booking.error.invalid.email"))
      .required(t("booking.error.required.email")),
    terms: Yup.bool() // use bool instead of boolean
      .oneOf(
        [true],
        t("individual.booking.schedule.choose_schedule.accept_policy")
      ),
  }).required();

  const {
    handleSubmit,
    control,
    setError,
    getValues,
    setValue,
    trigger,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
    defaultValues: {
      organization_id: organizationId,
      firstname: "",
      lastname: "",
      email: "",
      mobile: "",
      notes: "",
      topic: "",
      address: "",
      other_infos: "",
      price: 0,
      terms: false,
      new_letter: true,
    },
  });

  const getPaymentDetails = React.useCallback(() => {
    http
      .get("/api/general/individual/coach/client-payment-details", {
        params: {
          user_id: userId,
          organization_id: organizationId,
          session_slug: session_slug,
          client_id: client_id,
          type: payment_type,
          methods,
          base_url: window.location.origin,
        },
      })
      .then(({ data }) => {
        setClientPaymentDetails(data);
        let status =
          data?.paypal_status === "paid"
            ? true
            : data?.stripe_status === "paid"
            ? true
            : false;
        setIsBookingPaid(status);
      })
      .catch((err) => console.log(err.message));
  }, [userId, organizationId, session_slug, client_id, payment_type, methods]);

  const getCoachDetails = React.useCallback(() => {
    http
      .get("/api/general/individual/coach/coach-details", {
        params: {
          user_id: userId,
          organization_id: organizationId,
        },
      })
      .then(({ data }) => {
        setCoachDetails(data);
      })
      .catch((err) => console.log(err.message));
  }, [userId, organizationId]);

  const getClientDetails = React.useCallback(() => {
    http
      .get("/api/general/individual/coach/client-details", {
        params: {
          client_id: client_id,
        },
      })
      .then(({ data }) => {
        if (data?.id) {
          setClientDetails(data);
          [
            { name: "firstname", value: data?.firstname },
            { name: "lastname", value: data?.lastname },
            { name: "email", value: data?.email },
            { name: "mobile", value: data?.phone ?? "" },
          ].forEach(({ name, value }) => setValue(name, value));
        }
      })
      .catch((err) => console.log(err.message));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client_id]);

  const getOfferDetails = useCallback(() => {
    http
      .get("/api/general/individual/offer/details", {
        params: {
          offer_id: offerId,
          organization_id: organizationId,
          user_id: userId,
        },
      })
      .then(({ data }) => {
        if (data.message === "Success") {
          setSelectedOffer(data.data);
          setValue("price", data.data.price);
          setValue("topic", data.data.name);

          if (data.data?.status === "inactive") {
            showSnackbar(t("booking.error.package_disabled"), "success");
          }
        }
      })
      .catch((err) => console.log(err.message))
      .finally(() => {
        setIsLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerId, organizationId, userId]);

  const getCurrentOfferAppointment = useCallback(() => {
    // get all appointment for this offer id
    http
      .get(`/api/general/individual/offer/details/get-current-offer-appts`, {
        params: {
          offer_id: offerId,
          organization_id: organizationId,
          user_id: userId,
        },
      })
      .then((response) => setAppForThisOffer(response.data));
  }, [offerId, organizationId, userId]);

  const validateOnNext = async () => {
    let error = [];
    if (activeStep === 0) {
      if (Object.keys(selectedSessionTime).length === 0) {
        error = "date and time is required";
      }
    }

    if (activeStep === 2) {
      let customError = [];

      let info = await trigger([
        "firstname",
        "lastname",
        "email",
        "mobile",
        "topic",
        "terms",
      ]);

      if (!info) {
        error = "infor-has-an-error";
        customError = "infor-has-an-error";
      }

      if (selectedOffer?.fields.length > 0) {
        let checkHasRequired = selectedOffer?.fields.filter((x) =>
          Boolean(x.required)
        );

        if (checkHasRequired.length > 0) {
          checkHasRequired.forEach((xfield) => {
            let field = Helper.createSlug(xfield.name);

            if (!Boolean(getValues(`${field}`))) {
              setError(field, {
                required: true,
                message: t("booking.error.required.dynamic_field", {
                  field: xfield.name,
                }),
              });

              error = `${field}-has-an-error`;
              customError = `${field}-has-an-error`;
            }
          });
        }
      }

      if (customError.length === 0) {
        // redirect to survey form before payment
        if (selectedOffer?.survey.length > 0) {
          if (
            surveyOption?.[0]?.type === "view-first-survey" &&
            surveyHasAnswered
          ) {
            submitButtonRef.current.click();
            return;
          }
          setActiveStep(3);
          return;
        }
      }
    }

    if (activeStep === 2) {
      let customError = [];

      if (
        !surveyOption?.[0]?.type ||
        surveyOption?.[0]?.type === "view-first-calendar"
      ) {
        if (selectedOffer?.survey.length > 0) {
          selectedOffer?.survey.forEach((survey) => {
            if (survey?.questionslist.length > 0) {
              survey?.questionslist.forEach((item) => {
                let field = "";
                if (item.type === "question-short-answer") {
                  field = `answer[_${item.id}][_${item.choices[0].id}`;
                }

                if (item.type === "question-comment-essay") {
                  field = `answer[_${item.id}][_${item.choices[0].id}`;
                }

                if (item.type === "question-one-answer") {
                  field = `answer[_${item.id}]`;
                }

                if (item.type === "question-multiple-answer") {
                  field = `answer[_${item.id}]`;
                }

                if (item.type === "question-true-false") {
                  field = `answer[_${item.id}]`;
                }

                if (item.type === "question-yes-no") {
                  field = `answer[_${item.id}]`;
                }

                let current_value = getValues(field) ?? "";

                if (Boolean(item.is_required) && !current_value) {
                  error = `question-${field}-is-required`;
                  customError = `question-${field}-is-required`;
                  setError(field, {
                    required: true,
                    message: t("booking.error.required.question"),
                  });
                }
              });
            }
          });
        }
      }

      if (customError.length === 0) {
        // submit forms directly for free package
        // ignore next step and submit form if package is free
        const payments = selectedOffer?.price ?? 0;
        if (parseInt(payments) < 1) {
          error = "custom-error-to-prevent-next-step";
          submitButtonRef.current.click();
        }
      }
    }

    if (activeStep === 3) {
      if (parseInt(getValues("price")) > 0) {
        if (selectedPaymentMethod === null) {
          error = "payment-method-error";
          setError("price", {
            required: true,
            message: t("booking.error.required.payment_method"),
          });
        }
      }
    }

    if (error.length === 0) {
      clearErrors();
      handleNext();
    } else {
      console.log("error:", error);
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 0) {
      navigate(0);
      return;
    }

    if (activeStep === 3) {
      if (
        surveyOption?.[0]?.type === "view-first-survey" &&
        surveyHasAnswered
      ) {
        setActiveStep(1);
        return;
      }
    }

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCheckPaypalInterval = (type) => {
    if (type === "DAY") return "D";
    if (type === "WEEK") return "W";
    if (type === "MONTH") return "M";
    if (type === "YEAR") return "Y";
  };

  const jsonToFormData = (json) => {
    const formData = new FormData();

    for (const key in json) {
      if (json.hasOwnProperty(key)) {
        formData.append(key, json[key]);
      }
    }

    let extraInfoHasRequiredUpload = selectedOffer?.fields.filter(
      (x) => x.type === "upload"
    );

    if (extraInfoHasRequiredUpload.length) {
      extraInfoHasRequiredUpload.forEach((x) => {
        let field_name = Helper.createSlug(x.name);
        let files = extraFieldSelectedFiles[Helper.createSlug(x.name)] ?? [];

        Array.from(files).forEach((element) => {
          formData.append(`${field_name}[]`, element);
        });
      });
    }

    return formData;
  };

  const onSubmit = async (values) => {
    let bookingTimeUTCFormat = moment
      .tz(selectedSessionTime.start, "YYYY/MM/DD HH:mm", selectedTimezone)
      .tz(Helper.zeroTimezone);

    const offer_fields_with_upload = selectedOffer?.fields?.filter(
      (x) => x.type === "upload"
    );

    let data = {
      ...values,
      ...(Booking.isIntegrationActive(selectedOffer, "active compaign") &&
        Boolean(selectedOffer?.custom_options?.active_compaign_config) && {
          active_compaign_config: JSON.stringify(
            selectedOffer?.custom_options?.active_compaign_config
          ),
        }),
      ...(Booking.isIntegrationActive(selectedOffer, "convert kit") &&
        Boolean(selectedOffer?.custom_options?.convertkit_config) && {
          convertkit_config: JSON.stringify(
            selectedOffer?.custom_options?.convertkit_config
          ),
        }),
      ...(Booking.isIntegrationActive(selectedOffer, "aweber") &&
        Boolean(selectedOffer?.custom_options?.aweber_config) && {
          aweber_config: JSON.stringify(
            selectedOffer?.custom_options?.aweber_config
          ),
        }),
      ...(Booking.isIntegrationActive(selectedOffer, "getresponse") &&
        Boolean(selectedOffer?.custom_options?.getresponse_config) && {
          getresponse_config: JSON.stringify(
            selectedOffer?.custom_options?.getresponse_config
          ),
        }),
      ...(Booking.isIntegrationActive(selectedOffer, "mailchimp") &&
        Boolean(selectedOffer?.custom_options?.mailchimp_config) && {
          mailchimp_config: JSON.stringify(
            selectedOffer?.custom_options?.mailchimp_config
          ),
        }),
      ...(Booking.isIntegrationActive(selectedOffer, "mailerlite") &&
        Boolean(selectedOffer?.custom_options?.mailerlite_config) && {
          mailerlite_config: JSON.stringify(
            selectedOffer?.custom_options?.mailerlite_config
          ),
        }),

      paymentMethod: JSON.stringify({
        type: methods,
        mode: payment_type === "direct_payment" ? "payment" : "subscription",
      }),
      base_url: window.location.origin,
      duration: selectedOffer?.duration,
      date: moment(new Date(selectedSessionTime.start), "YYYY/MM/DD").format(
        "YYYY/MM/DD"
      ),
      time: moment(new Date(selectedSessionTime.start), "hh:mm A").format(
        "hh:mm A"
      ),
      date_end: moment(new Date(selectedSessionTime.end), "YYYY/MM/DD").format(
        "YYYY/MM/DD"
      ),
      time_end: moment(new Date(selectedSessionTime.end), "hh:mm A").format(
        "hh:mm A"
      ),
      sessions: JSON.stringify(selectedOffer?.custom_options?.sessions),
      address: values.address, // address is required in calendar
      other_infos: values.other_infos,
      description: selectedOffer?.description,
      phone: values.mobile,
      color: eventColor,
      success_url: `${window.location.origin}/appointment-individual/booking/payment-successful`,
      cancel_url: `${window.location.origin}/appointment-individual/booking/payment-cancelled`,
      offer_fields: JSON.stringify(selectedOffer?.fields),
      user_id: selectedOffer?.user_id,
      organization_id: organizationId,
      offer_id: offerId,
      timezone: Timezone.getLabel(selectedTimezone),
      timezone_utc: selectedTimezone,
      utc_bookingdate: bookingTimeUTCFormat.format("YYYY/MM/DD HH:mm"),
      coach_bookingdate: bookingTimeUTCFormat
        .clone()
        .tz(coachDetails?.timezone_utc)
        .format("YYYY-MM-DD HH:mm"),
      coach_utc_timezone: coachDetails?.timezone_utc,
      ...(selectedOffer?.survey?.length > 0 &&
        JSON.parse(selectedOffer.custom_options.survey)?.[0]?.type ===
          "first-view-calendar" && {
          survey: JSON.stringify(selectedOffer?.survey),
          ...(values.answer.length > 0 && {
            answer: values.answer,
          }),
        }),

      offer_amount: selectedOffer?.price,
      offer_name: selectedOffer?.name,
      topic: selectedOffer?.name,
      ...(selectedPaymentMethod?.type === "paypal" && {
        paypal_config: JSON.stringify(
          selectedOffer?.custom_options?.paypal_config
        ),
        paypal_config_interval: handleCheckPaypalInterval(
          selectedOffer?.custom_options?.paypal_config.interval_unit
        ),
      }),
      number_of_sessions:
        selectedOffer?.custom_options?.number_of_sessions ?? 1,
      booking_status: "check_pending",
      session_slug: session_slug,
      meeting_type:
        selectedOffer?.custom_options?.meeting_type ?? "virtual-meeting",
      currency: selectedOffer?.custom_options?.currency ?? "USD",
      ...(offer_fields_with_upload?.length > 0 && {
        offer_fields_with_upload: JSON.stringify(offer_fields_with_upload),
      }),
    };

    let error = [];

    if (selectedOffer?.fields.length > 0) {
      let checkHasRequired = selectedOffer?.fields.filter((x) =>
        Boolean(x.required)
      );

      if (checkHasRequired.length > 0) {
        checkHasRequired.forEach((xfield) => {
          let field = Helper.createSlug(xfield.name);

          let hasUploadedFiles = extraFieldSelectedFiles[field] ?? [];
          if (xfield.type === "upload") {
            if (hasUploadedFiles.length === 0) {
              // has error no files uploaded
              setError(field, {
                required: true,
                message: "No uploaded file",
              });

              error = `${field}-has-an-error-no-uploaded`;
            }
          } else {
            // has error empty fields
            if (!Boolean(getValues(`${field}`))) {
              setError(field, {
                required: true,
                message: t("booking.error.required.dynamic_field", {
                  field: xfield.name?.toLowerCase(),
                }),
              });

              error = `${field}-has-an-error`;
            }
          }
        });
      }
    }

    if (error.length > 0) {
      console.log("form has an error: ", error);
      return;
    }

    setIsProcess(true);

    http
      .post(
        "/api/general/individual/create-booking/payment-first-process",
        jsonToFormData(data)
      )
      .then((response) => {
        if (response.data.message === "current-session-incomplete") {
          setFormValues(data);
          setHasPending(true);
        }

        if (response.data.message === "success") {
          if (response.data.payment_url) {
            setIsProcess(true);
            window.location.href = response.data.payment_url;
          } else {
            setActiveStep(4);
          }
        }
      })
      .catch((err) => console.log("error indi create booking:", err))
      .finally(() => setIsProcess(false));
  };

  useEffect(() => {
    setTimeout(() => {
      getPaymentDetails();
      getCurrentOfferAppointment();
      getCoachDetails();
      getOfferDetails();
      getClientDetails();
    }, 5000);
  }, [
    getPaymentDetails,
    getOfferDetails,
    getCurrentOfferAppointment,
    getCoachDetails,
    getClientDetails,
  ]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (selectedOffer === null) {
    return <PageNotFound />;
  }

  if (
    parseInt(clientPaymentDetails?.session_details?.session_records_count) > 0
  ) {
    return (
      <BookingSuccessfullyCompleted
        coach_personal_link={coachDetails?.onboarding?.personal_link}
      />
    );
  }

  if (
    methods === "stripe" &&
    clientPaymentDetails?.stripe_status === "unpaid"
  ) {
    return <PaymentNotYetReflected methods="Stripe" />;
  }

  if (
    methods === "paypal" &&
    clientPaymentDetails?.paypal_status === "unpaid"
  ) {
    return <PaymentNotYetReflected methods="Paypal" />;
  }

  let coachOrg = coachDetails?.organizations?.[0];
  let coachOrgSettings = coachOrg?.settings
    ? JSON.parse(coachOrg?.settings)
    : null;

  const user_settings = coachDetails?.settings
    ? JSON.parse(coachDetails?.settings)
    : null;
  const langauge_settings = user_settings?.langauge_settings;
  const appointment_settings = user_settings?.appointment_settings;

  const userDateFormat = langauge_settings?.date_format ?? "MM/DD/YYYY";
  const userTimeFormat = langauge_settings?.time_format ?? "hh:mm A";

  const userAdvanceBookingNotice =
    appointment_settings?.advance_booking_notice ?? 3; // months

  const userMinimumBookingNotice =
    appointment_settings?.minimum_booking_notice ?? 0; // hours

  const calendarMaxDate = moment().add(userAdvanceBookingNotice, "M");

  const packagePrice = selectedOffer?.price ?? 0;

  let surveyOption = selectedOffer?.custom_options?.survey
    ? JSON.parse(selectedOffer.custom_options.survey)
    : null;

  const customProps = {
    handleSubmit,
    onSubmit,
    randomState,
    setRandomState,
    randomErrors,
    setRandomErrors,
    setValue,
    getValues,
    maxSteps,
    handleBack,
    validateOnNext,
    isProcess,
    packagePrice,
    errors,
    control,
    setSelectedTimezone,
    selectedTimezone,
    sessionList,
    selectedSessionTime,
    setSessionSelectedTime,
    setSessionList,
    selectedOffer,
    setBlockTimeOnSelectedDate,
    appForThisOffer,
    blockTimeOnSelectedDate,
    selectedDate,
    setSelectedDate,
    setError,
    clearErrors,
    coachOrgSettings,
    coachOrg,
    coachDetails,
    nextButtonRef,
    calendarMaxDate,
    userDateFormat,
    userTimeFormat,
    userMinimumBookingNotice,
    surveyHasAnswered,
    activeStep,
    submitButtonRef,
    isBookingPaid,

    allUpcommingAppt,
    setAllUpcommingAppt,
    preparingSessionList,
    setPreparingSesisonList,
    setActiveStep,
    overFlowTimeToRender,
    setOverFlowTimeToRender,
    underFlowTimeToRender,
    setUnderFlowTimeToRender,
    isUseTimeRecord,
    setIsUseTimeRecord,
    timeRecords,
    setTimeRecords,
    extraFieldSelectedFiles,
    setExtraFieldSelectedFiles,
  };

  return (
    <Page title={`${selectedOffer?.name} - ${coachDetails?.name}`}>
      <Box
        sx={{
          backgroundColor: "#FAFAFA",
          // minHeight: "calc(100vh - 70px)",
          minHeight: "calc(100vh - 5px)",
          pb: "30px",
        }}
      >
        <Box
          style={{
            marginTop: "5px",
            marginLeft: "5px",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "24px",
            cursor: "pointer",
            textTransform: "capitalize",
          }}
        >
          <Box
            sx={{
              display: activeStep === 4 ? "none" : "initial",
            }}
          >
            {surveyOption &&
            surveyOption.length > 0 &&
            surveyOption?.[0]?.type === "view-first-survey" &&
            !surveyHasAnswered ? (
              <Box
                sx={{
                  backgroundColor: "#FFFFFF",
                  // p: "40px 10px 32px",
                  // border: "1px solid red",
                }}
              >
                <CoachHeader coachDetails={coachDetails} />
              </Box>
            ) : (
              <BookingHeader
                selectedOffer={selectedOffer}
                selectedSessionTime={selectedSessionTime}
                selectedDate={selectedDate}
                handleBack={handleBack}
                coachDetails={coachDetails}
              />
            )}
          </Box>
        </Box>

        <Container
          maxWidth={"cplc"}
          sx={{
            px: { xs: "30px", md: "10px !important" },
          }}
        >
          {surveyOption &&
            surveyOption.length > 0 &&
            surveyOption?.[0]?.type === "view-first-survey" &&
            !surveyHasAnswered && (
              <TakeSurveyForm
                surveyId={surveyOption?.[0]?.survey_id}
                surveySlug={surveyOption?.[0]?.survey_slug}
                surveyIsRequired={surveyOption?.[0]?.is_required}
                setSurveyHasAnswered={setSurveyHasAnswered}
                setValue={setValue}
                clientDetails={clientDetails}
              />
            )}

          {surveyOption?.[0]?.type === "view-first-survey" &&
            surveyHasAnswered && (
              <>
                <RenderBooking {...customProps} hideThisSurvey={true} />
              </>
            )}

          {(!surveyOption?.[0]?.type ||
            surveyOption?.[0]?.type === "view-first-calendar") && (
            <>
              <RenderBooking {...customProps} hideThisSurvey={false} />
            </>
          )}

          {hasPending && (
            <BookingWarningDialog
              formValues={formValues}
              setFormValues={setFormValues}
              hasPending={hasPending}
              setHasPending={setHasPending}
              setActiveStep={setActiveStep}
            />
          )}
        </Container>
      </Box>

      <Box
        sx={{
          display: activeStep === 4 ? "none" : "initial",
        }}
      >
        <PowerdBy colored={true} />
      </Box>
    </Page>
  );
};

export default StepperPaidBookingMobile;

const PaymentNotYetReflected = ({ methods }) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        minHeight: "calc(100vh)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Box>
        <Typography
          sx={{
            fontSize: "14px",
            lineHeight: "16px",
            textAlign: "center",
          }}
        >
          {methods} {t("individual.booking.schedule.payment_not_yet_reflected")}
        </Typography>
      </Box>

      <Box>
        <Button onClick={() => window.location.reload()}>
          {" "}
          {t("individual.booking.schedule.choose_schedule.button.reload")}{" "}
        </Button>
      </Box>
    </Box>
  );
};

const BookingSuccessfullyCompleted = ({ coach_personal_link }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        minHeight: "calc(100vh)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Box>
        <Typography
          sx={{
            fontSize: "16px",
            lineHeight: "31px",
            textAlign: "center",
            fontWeight: 700,
          }}
        >
          {t("individual.booking.schedule.booking_completed")}
        </Typography>

        <Typography
          sx={{
            fontSize: "14px",
            lineHeight: "16px",
            textAlign: "center",
          }}
        >
          {t("individual.booking.schedule.booking_completed.description")}
        </Typography>
      </Box>

      <Box>
        <Button onClick={() => navigate(`/a/${coach_personal_link}`)}>
          {t(
            "individual.booking.schedule.choose_schedule.button.coach_packages"
          )}
        </Button>
      </Box>
    </Box>
  );
};
