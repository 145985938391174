import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/system";
import React, { useContext, useState } from "react";
import * as Yup from "yup";

import http from "src/utils/http";
import Logo from "src/components/Logo";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller as Fc } from "react-hook-form";
import TreffasStyle from "src/utils/TreffasStyle";

import SideBg from "../SideBg";
import { useTranslation } from "react-i18next";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Users } from "src/context";

const Login = () => {
  const themes = useTheme();
  const [isProcess, setIsProcess] = useState(false);

  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const { mixpanel } = useContext(Users);

  const Schema = Yup.object({
    email: Yup.string()
      .email(t("login.form.validation.email_invalid"))
      .required(t("login.form.validation.email_required")),
    password: Yup.string().required(
      t("login.form.validation.pasword_required")
    ),
  }).required();

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
    defaultValues: {
      // organization: "",
      email: "",
      password: "",
    },
  });

  const onLogin = (values) => {
    setIsProcess(true);
    http
      .post("/api/oauth/login-general-password", {
        ...values,
      })
      .then(({ data }) => {
        if (data.message === "success") {
          localStorage.setItem(`accessToken`, data.access_token);
          localStorage.setItem(`__loginType`, "coach");
          window.location.href = `/app/v2/calendar`;
        }
      })
      .catch((err) => {
        console.log(err.message);
      })
      .finally(() => setIsProcess(false));
  };

  React.useEffect(() => {
    mixpanel.track("Login Page", {
      location: window.location.href,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box
        sx={{
          minHeight: "calc(100vh - 0px)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "center",
            minHeight: "calc(100vh - 0px)",
          }}
        >
          <Box flexGrow={1} sx={{}}>
            {/* logo */}
            <Box
              sx={(themes) => ({
                height: "80px",
                alignItems: "center",
                [themes.breakpoints.up("md")]: {
                  alignItems: "center",
                  boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.04)",
                  pl: "99px",
                  display: "none",
                },
                [themes.breakpoints.down("md")]: {
                  justifyContent: "flex-start",
                  pl: "24px !important",

                  display: "flex",
                },
              })}
            >
              <Logo />
            </Box>

            <Box
              sx={{
                display: {
                  xs: "block",
                  sm: "flex",
                },
                justifyContent: "center",
                alignItems: "center",
                px: "24px",
              }}
            >
              <Box
                sx={{
                  mt: "30px",
                }}
                // border={1}
              >
                {/* login info */}
                <Box>
                  <Box mb={"4px"}>
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: "32px",
                        lineHeight: "46px",
                        textAlign: "center",
                        letterSpacing: "-0.01em",
                        color: "#060A2D",
                      }}
                    >
                      Welcome back admin!
                    </Typography>
                  </Box>

                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "24px",
                      textAlign: "center",
                      color: "#838383",
                    }}
                  >
                    This page is for admin only, debug and checking purposes
                  </Typography>

                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "24px",
                      textAlign: "center",
                      color: "#838383",
                    }}
                  >
                    {t("login.description.second")}
                  </Typography>
                </Box>

                {/* divider */}
                <Box
                  sx={{
                    width: "100%",
                    mt: "34px",
                    textAlign: "center",
                    alignContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                  }}
                >
                  <Box
                    sx={{
                      margin: "auto",
                    }}
                  >
                    <Box>
                      <Divider>
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "24px",
                            textAlign: "center",
                            color: "#838383",
                          }}
                        >
                          {t("login.description.third")}
                        </Typography>
                      </Divider>
                    </Box>
                  </Box>
                </Box>

                {/* login form */}
                <Box
                  sx={{
                    width: "100%",
                    mt: "20px",
                  }}
                >
                  <Box
                    sx={{
                      maxWidth: "460px",
                      m: "auto",
                    }}
                  >
                    <form onSubmit={handleSubmit(onLogin)}>
                      <Box sx={{ mt: "24px" }}>
                        <FormControl fullWidth variant="standard">
                          <Typography sx={TreffasStyle.formLabel}>
                            {t("login.form.email")}
                          </Typography>

                          <Fc
                            name="email"
                            control={control}
                            rules={{
                              required: true,
                            }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                placeholder={t("login.form.placeholder.email")}
                                type="text"
                                sx={TreffasStyle.formInput(themes)}
                                error={Boolean(errors.email)}
                                helperText={errors.email?.message}
                              />
                            )}
                          />
                        </FormControl>
                      </Box>

                      <Box sx={{ mt: "24px" }}>
                        <FormControl fullWidth variant="standard">
                          <Typography sx={TreffasStyle.formLabel}>
                            {t("login.form.password")}
                          </Typography>

                          <Fc
                            name="password"
                            control={control}
                            rules={{
                              required: true,
                            }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                placeholder={t(
                                  "login.form.placeholder.password"
                                )}
                                type={showPassword ? "text" : "password"}
                                sx={TreffasStyle.formInput(themes)}
                                error={Boolean(errors.password)}
                                helperText={errors.password?.message}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={() =>
                                          setShowPassword(!showPassword)
                                        }
                                      >
                                        {!showPassword ? (
                                          <Visibility
                                            sx={{ fontSize: "18px" }}
                                          />
                                        ) : (
                                          <VisibilityOff
                                            sx={{ fontSize: "18px" }}
                                          />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </Box>

                      {errors.submit && (
                        <Box>
                          <FormHelperText
                            error
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            {errors.submit.message}
                          </FormHelperText>
                        </Box>
                      )}

                      <Box sx={{ mt: "16px" }}>
                        <Button
                          variant="contained"
                          fullWidth
                          type="submit"
                          sx={{
                            textTransform: "inherit",
                            borderRadius: "8px",
                            fontSize: "14px",
                            padding: "10px",
                            lineHeight: "31px",
                            fontWeight: 400,
                            height: "48px",
                          }}
                          disabled={isProcess}
                          startIcon={
                            isProcess && (
                              <CircularProgress color="inherit" size={15} />
                            )
                          }
                        >
                          {/* Log in */}
                          {t("login.form.login_btn")}
                        </Button>
                      </Box>
                    </form>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box>
            <SideBg />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Login;
