import { Box, Container, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import IsMounted from "src/components/IsMounted";
import http from "src/utils/http";
import { useTranslation } from "react-i18next";
import PageNotFound from "src/components/PageNotFound";
import LoadingScreen from "src/components/LoadingScreen";
import Page from "src/components/Page";
import Header from "src/views/general/individual-booking/schedule/header";
import PowerdBy from "../../PowerdBy";
import { Users } from "src/context";
import Consultations from "./category/consultations";
import DigitalPackage from "./category/digital-package";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const OffersList = ({ hideHeader, hideFooter }) => {
  const mounted = IsMounted();
  const [offerList, setOfferList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { personalLink } = useParams();
  const [coachDetails, setCoachDetails] = useState(null);
  const { t } = useTranslation();
  const { mixpanel } = useContext(Users);

  const searchParams = useQuery();
  const display = searchParams.get("display");
  const header = searchParams.get("header");
  const footer = searchParams.get("footer");

  const getOffers = React.useCallback(() => {
    http
      .get("/api/general/individual/coach/offer/list-bypersonal-link", {
        params: {
          personal_link: personalLink,
        },
      })
      .then(({ data }) => {
        if (mounted.current) {
          setOfferList(data);
        }
      })
      .catch((err) => console.log(err.message));
  }, [personalLink, mounted]);

  const getCoachDetails = React.useCallback(() => {
    http
      .get("/api/general/individual/coach/coach-details-bypersonal-link", {
        params: {
          personal_link: personalLink,
        },
      })
      .then(({ data }) => {
        if (mounted.current) {
          if (Object.keys(data).length > 0) {
            setCoachDetails(data);
          }
        }
      })
      .catch((err) => console.log(err.message))
      .finally(() => setIsLoading(false));
  }, [personalLink, mounted]);

  useEffect(() => {
    mixpanel.track("Coach Packages", {
      location: window.location.href,
    });

    getOffers();
    getCoachDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOffers, getCoachDetails]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (coachDetails === null) {
    return <PageNotFound />;
  }

  const isIframe = window.self !== window.top;

  const consultations = offerList.filter(
    (x) => x.category !== "digital-products"
  );

  const digital_products = offerList.filter(
    (x) => x.category === "digital-products"
  );

  return (
    <Page title={`Treffas - ${coachDetails?.name}`}>
      {!hideHeader && (
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            p: { xs: "0px", sm: "40px 10px 32px" },
          }}
        >
          <Header coachDetails={coachDetails} />
        </Box>
      )}

      <Box
        sx={{
          backgroundColor: "#FAFAFA",
          minHeight:
            display === "embeded" || isIframe
              ? "inherit"
              : "calc(100vh - 70px)",
          pb: "24px",
          px: "24px",
        }}
      >
        <Container
          maxWidth={"ntc"}
          sx={(themes) => ({
            padding: "0px !important",
          })}
        >
          {consultations.length === 0 && digital_products.length === 0 ? (
            <Box sx={{ pt: "24px" }}>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#6F6C90",
                  textAlign: "center",
                }}
              >
                {t("booking.error.no_offer_found")}
              </Typography>
            </Box>
          ) : (
            <Box sx={{ pt: "40px" }}>
              <Box
                sx={{ display: consultations.length === 0 ? "none" : "block" }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontFamily: "Mukta",
                      fontWeight: 700,
                      lineHeight: "14px",
                      letterSpacing: "0.91px",
                      color: "#6E757C",
                    }}
                  >
                    CONSULTATIONS
                  </Typography>
                </Box>

                <Box sx={{ mt: "8px" }}>
                  <Consultations
                    offerList={consultations}
                    coachDetails={coachDetails}
                    display={display}
                    header={header}
                    footer={footer}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  mt: "28px",
                  display: digital_products.length === 0 ? "none" : "block",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontFamily: "Mukta",
                      fontWeight: 700,
                      lineHeight: "14px",
                      letterSpacing: "0.91px",
                      color: "#6E757C",
                    }}
                  >
                    DIGITAL PRODUCTS
                  </Typography>
                </Box>

                <Box sx={{ mt: "8px" }}>
                  <DigitalPackage
                    offerList={digital_products}
                    coachDetails={coachDetails}
                    display={display}
                    header={header}
                    footer={footer}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Container>
      </Box>

      {/* powered by */}
      {!hideFooter && (
        <Box>
          <PowerdBy colored={true} />
        </Box>
      )}
    </Page>
  );
};

export default OffersList;
