import { lazy } from "react";
import AuthGuard from "src/components/guard/AuthGuard";
import GuardedBaseLayout from "src/layout/menu/guarded";

const Integrations = [
  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/integrations",
    component: lazy(() => import("src/views/coach/integrations")),
    show: true,
    name: "navbar.integrations",
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/integrations/details/:integrationId",
    component: lazy(() => import("src/views/coach/integrations/details")),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/integrations/details/:integrationId:",
    component: lazy(() => import("src/views/coach/integrations/details")),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/integrations/details/:integrationId/mailchimp-veryfication",
    component: lazy(() =>
      import("src/views/coach/integrations/details/mailchimp/Configuration")
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/integrations/details/:integrationId/aweber-veryfication",
    component: lazy(() =>
      import("src/views/coach/integrations/details/aweber/Configuration")
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/integrations/details/:integrationId/getresponse-veryfication",
    component: lazy(() =>
      import("src/views/coach/integrations/details/get-response/Configuration")
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/integrations/details/:integrationId/googlecalendar-veryfication",
    component: lazy(() =>
      import(
        "src/views/coach/integrations/details/google-calendar/Configuration"
      )
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/integrations/googlecalendar/pick-calendar/:integrationId",
    component: lazy(() =>
      import(
        "src/views/coach/integrations/details/google-calendar/PickCalendar"
      )
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/integrations/details/:integrationId/outlook-veryfication",
    component: lazy(() =>
      import("src/views/coach/integrations/details/ms-outlook/Configuration")
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/integrations/msoutlook/pick-calendar/:integrationId",
    component: lazy(() =>
      import("src/views/coach/integrations/details/ms-outlook/PickCalendar")
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/integrations/details/:integrationId/skype-veryfication",
    component: lazy(() =>
      import("src/views/coach/integrations/details/skype/Configuration")
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/integrations/details/:integrationId/stripe-veryfication",
    component: lazy(() =>
      import("src/views/coach/integrations/details/stripe/Configuration")
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/integrations/details/:integrationId/zoom-veryfication",
    component: lazy(() =>
      import("src/views/coach/integrations/details/zoom/Configuration")
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/integrations/details/:integrationId/zoom-test-veryfication",
    component: lazy(() =>
      import("src/views/coach/integrations/details/zoom/ConfigurationTest")
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/integrations/details/:integrationId/paypal-veryfication",
    component: lazy(() =>
      import("src/views/coach/integrations/details/paypal/Configuration")
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/integrations/details/:integrationId/googlemeet-veryfication",
    component: lazy(() =>
      import("src/views/coach/integrations/details/google-meet/Configuration")
    ),
    show: false,
  },

  // settings additional calendar routes

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/integrations/settings/calendar/google-verifications",
    component: lazy(() =>
      import(
        "src/views/coach/settings/calendar/integrated/google/Configuration"
      )
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/integrations/settings/calendar/outlook-verifications",
    component: lazy(() =>
      import(
        "src/views/coach/settings/calendar/integrated/outlook/Configuration"
      )
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/integrations/settings/calendar/google-pick-calendar",
    component: lazy(() =>
      import("src/views/coach/settings/calendar/integrated/google/PickCalendar")
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/integrations/settings/calendar/outlook-pick-calendar",
    component: lazy(() =>
      import(
        "src/views/coach/settings/calendar/integrated/outlook/PickCalendar"
      )
    ),
    show: false,
  },
];

export default Integrations;
