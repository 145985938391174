import React from "react";
import { Navigate } from "react-router-dom";
import { Users } from "src/context";

function GuestGuard({ children }) {
  const { user } = React.useContext(Users);

  if (user) {
    return <Navigate to="/app" />;
  }

  return children;
}

export default GuestGuard;
