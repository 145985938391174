import React from "react";

const IconCheckbox = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <rect
        width="19"
        height="19"
        x="0.5"
        y="0.5"
        fill="#0173FE"
        stroke="#0173FE"
        rx="4.5"
      ></rect>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M14.523 6.97c.22.22.22.575 0 .795l-5.25 5.25a.563.563 0 01-.796 0l-3-3a.562.562 0 11.796-.796l2.602 2.602 4.852-4.852c.22-.22.576-.22.796 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default IconCheckbox;
