import { Box, Grid, Link, Typography } from "@mui/material";
import React from "react";
import CardWithThumbnail from "src/components/thumbnail";

const Attachments = ({ package_files }) => {
  return (
    <>
      <Grid item xs={12}>
        <Box>
          {package_files.length > 0 && (
            <>
              <Box>
                <Typography
                  sx={{
                    color: "#6E757C",
                    fontFamily: "Mukta",
                    fontSize: "11px",
                    fontStyle: "normal",
                    fontWeight: "700",
                    lineHeight: "14px",
                    letterSpacing: "0.77px",
                  }}
                >
                  {package_files?.length} ATTACHMENTS
                </Typography>
              </Box>
              <Box>
                {package_files.map((attachment, key) => {
                  return (
                    <Box key={key}>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "31px",
                          color: "#4A5266",
                          mt: "16px",
                          mb: "12px",
                        }}
                      >
                        {attachment?.description}
                      </Typography>

                      <Box>
                        {attachment?.uploaded_files?.length > 0 && (
                          <Grid container spacing={"12px"}>
                            {attachment?.uploaded_files.map((file, fileKey) => {
                              return (
                                <Grid item xs={12} key={fileKey}>
                                  <CardWithThumbnail file={file} />
                                </Grid>
                              );
                            })}
                          </Grid>
                        )}
                      </Box>

                      {attachment?.link && (
                        <Box sx={{ mt: "12px" }}>
                          <Typography
                            sx={{
                              color: "#6E757C",
                              fontFamily: "Mukta",
                              fontSize: "11px",
                              fontStyle: "normal",
                              fontWeight: "700",
                              lineHeight: "14px",
                              letterSpacing: "0.77px",
                            }}
                          >
                            LINK
                          </Typography>

                          <Link
                            sx={{
                              fontWeight: 500,
                              fontSize: "14px",
                              lineHeight: "24px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              window.open(attachment?.link, "__blank");
                            }}
                          >
                            {attachment?.link}
                          </Link>
                        </Box>
                      )}
                    </Box>
                  );
                })}
              </Box>
            </>
          )}
        </Box>
      </Grid>
    </>
  );
};

export default Attachments;
